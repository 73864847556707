import * as yup from 'yup';
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import defaults from 'lodash/defaults';
import Container from '@components/atoms/Container';
import PartsStore from '@components/organisms/PartsStore';

export default function CarPartsShopStorefrontBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    CarPartsShopStorefrontBlock.dataSchema,
  );
  const {
    dealerId,
  } = elementData;

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(10),
      }}
    >
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        <PartsStore
          dealerId={dealerId}
          display="fullShop"
        />
      </Container>
    </Box>
  );
}

CarPartsShopStorefrontBlock.typeName = 'ComponentContentCarPartsShopStorefront';
CarPartsShopStorefrontBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    dealerId: PropTypes.string,
  }).isRequired,
};
CarPartsShopStorefrontBlock.dataSchema = yup.object().shape({
  id: yup.number().required(),
  title: TypographyElement.dataSchema.nullable(),
  dealerId: yup.string().nullable(),
});
CarPartsShopStorefrontBlock.graphQlSchema = `
... on ${CarPartsShopStorefrontBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  dealerId
}
`;
