const yup = require('yup');
const _ = require('lodash');

function deserializeValue(
  state,
  { isMultiple, options, companionField },
  dependencies,
  { key, values },
) {
  if (isMultiple && !Array.isArray(state.value)) {
    return [state.value];
  } if (!isMultiple && Array.isArray(state.value)) {
    if (state.value.length > 0) return state.value[0];
    return '';
  }

  if (companionField) {
    options.forEach((option) => {
      // eslint-disable-next-line no-param-reassign
      values[`${key}_companion_value_${option.value}`] = state[`companion_value_${option.value}`] ?? '';
    });
  }

  return state.value;
}

module.exports = {
  getDefaultValue: ({ isMultiple }) => (isMultiple ? [] : ''),
  serializeValue: (value, { options, companionField }, dependencies, { key, values }) => {
    const newState = { value };

    if (companionField) {
      options.forEach((option) => {
        const companionFieldValue = values[`${key}_companion_value_${option.value}`];

        if (!companionFieldValue) return;

        newState[`companion_value_${option.value}`] = companionFieldValue.trim();
      });
    }

    const notYetAnsweredOption = options.find((o) => o.isNotYetAnswered);

    // Check, if the option that marks this as 'not answered' was selected
    if (!notYetAnsweredOption) return newState;

    const notYetAnsweredValue = notYetAnsweredOption.value || notYetAnsweredOption.label_de;

    if (value === notYetAnsweredValue) {
      newState.hasValue = false;
    }

    return newState;
  },
  deserializeValue,
  getDisplayValue: (...args) => {
    const state = args[0];
    const { options, isOptionsNewLineFunction, showInternalValue } = args[1];
    const value = deserializeValue(...args);
    const delimiter = isOptionsNewLineFunction ? '\n' : '; ';
    const valuePrefix = isOptionsNewLineFunction ? '\u{2022} ' : '';

    if (!value) {
      return '-';
    }

    if (Array.isArray(value)) {
      return value.map((item) => {
        const option = options.find((o) => _.isEqual(o.value || o.label, item));
        let optionLabel = showInternalValue
          ? (option?.internalLabel ?? option?.label ?? item)
          : (option?.label ?? item);

        if (option && state[`companion_value_${option.value}`]) {
          optionLabel = `${optionLabel} (${state[`companion_value_${option.value}`]})`;
        }

        return valuePrefix + optionLabel;
      }).join(delimiter);
    }

    const selectedOption = options
      .find((option) => _.isEqual(option.value || option.label, value));

    return showInternalValue
      ? (selectedOption?.internalLabel ?? selectedOption?.label ?? value)
      : (selectedOption?.label ?? value);
  },
  validationSchema: ({
    isMultiple, minOptions, maxOptions, options, companionField,
  }, { key, i18n, fieldValidations }) => {
    if (isMultiple) {
      if (companionField) {
        options.forEach((option) => {
          // eslint-disable-next-line no-param-reassign
          fieldValidations[`${key}_companion_value_${option.value}`] = yup
            .number()
            .min(companionField[0]?.minValue ?? 0, `${companionField[0]?.[`label_${i18n.i18n.language}`]} muss mind. \${min} betragen.`)
            .max(companionField[0]?.maxValue ?? 1000, `${companionField[0]?.[`label_${i18n.i18n.language}`]} darf max. \${max} betragen.`)
            .nullable();
        });
      }

      return yup
        .array()
        .of(yup.string())
        .min(
          minOptions ?? 0,
          i18n.t('modules.event.components.Form.fields.SelectField.validation.min', { count: minOptions }),
        )
        .max(
          maxOptions ?? Number.MAX_SAFE_INTEGER,
          i18n.t('modules.event.components.Form.fields.SelectField.validation.max', { count: maxOptions }),
        );
    }

    return yup.string().nullable();
  },
};
