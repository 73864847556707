import * as yup from 'yup';

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Grid2,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ButtonElement,
  LinkElement,
  MediaElement,
  TypographyElement,
  useContentElement,
} from '@plugins/next-cms-core';
import { FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';
import EmissionLabel from '../atoms/EmissionLabel';
import { animationDefaults, easings, timings } from '../../theme';

export default function CarPromotionBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    CarPromotionBlock.dataSchema,
  );

  const {
    cars,
  } = elementData;

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      textAlign: 'center',
      semanticVariant: 'h2',
      displayVariant: 'h3',
    });
  }

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
      }}
    >
      <Box>
        <Container>
          {title && (
            <Box mb={5}>
              <TypographyElement data={title} />
            </Box>
          )}
          <Grid2
            alignItems="stretch"
            container
            justifyContent="center"
            spacing={5}
          >
            {cars.map((car) => (
              <Grid2
                key={car.id}
                size={{
                  lg: 4,
                  md: 6,
                  xs: 12,
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Car item={car} theme={theme} />
              </Grid2>
            ))}
          </Grid2>
        </Container>
      </Box>
    </Box>
  );
}

function Car(props) {
  const {
    item,
    theme,
  } = props;

  const {
    title,
    subtitle,
    consumptionInnercity,
    consumptionOutercity,
    consumptionCombined,
    consumptionCombinedElectricity,
    consumptionCombinedHydrogen,
    emissions,
    energyEfficiencyClass,
    consumptionSuffix,
    media,
    link,
    text,
    prices,
    buttons,
  } = item;

  const carDetails1 = [];
  const carDetails2 = [];

  if (!isNil(consumptionInnercity)) {
    carDetails1.push({
      label: 'innerorts',
      value: consumptionInnercity.toLocaleString('de-DE'),
      suffix: 'l/100 km',
    });
  }

  if (!isNil(consumptionOutercity)) {
    carDetails1.push({
      label: 'außerorts',
      value: consumptionOutercity.toLocaleString('de-DE'),
      suffix: 'l/100 km',
    });
  }

  if (!isNil(consumptionCombined)) {
    carDetails1.push({
      label: 'kombiniert',
      value: consumptionCombined.toLocaleString('de-DE'),
      suffix: 'l/100 km',
    });
  }

  if (!isNil(consumptionCombinedElectricity)) {
    carDetails1.push({
      label: <span>Strom&shy;verbrauch kombiniert</span>,
      value: consumptionCombinedElectricity.toLocaleString('de-DE'),
      suffix: 'kWh/100 km',
    });
  }

  if (!isNil(consumptionCombinedHydrogen)) {
    carDetails1.push({
      label: <span>Wasserstoff&shy;verbrauch kombiniert</span>,
      value: consumptionCombinedHydrogen.toLocaleString('de-DE'),
      suffix: 'kg/100 km',
    });
  }

  if (!isNil(emissions)) {
    carDetails2.push({
      label:
  <span>
    CO
    <sub><small>2</small></sub>
    -Emissionen (kombiniert)
  </span>,
      value: emissions,
      suffix: 'g/km',
    });
  }

  if (energyEfficiencyClass) {
    carDetails2.push({
      label:
  <span>
    CO
    <sub><small>2</small></sub>
    -Klasse
  </span>,
      value: <EmissionLabel efficiency={energyEfficiencyClass} />,
    });
  }

  let lastRowWasSummary = false;

  return (
    <Card
      elevation={3}
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: `all ${timings.slowAnimationTiming}ms ${easings.defaultEasingCss}`,
        '&:hover': {
          transform: `scale(${animationDefaults.defaultScale})`,
        },
      }}
    >
      <LinkElement data={link ?? {}}>
        <CardActionArea
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            textDecoration: 'none !important',
          }}
        >
          {media && (
            <MediaElement
              data={media}
              isFluid
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
          )}
          <CardContent
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: 0,
            }}
          >
            <Box flex={1}>
              {subtitle && (
                <Typography
                  color="primary"
                  component="div"
                  variant="subtitle1"
                >
                  {subtitle}
                </Typography>
              )}
              {title && (
                <Typography component="h3" variant="h5">
                  {title}
                </Typography>
              )}
              {text && text.value && (
                <Box mt={2}>
                  <TypographyElement data={text} />
                </Box>
              )}
              {(carDetails1.length > 0 || carDetails2.length > 0) && (
                <Box mt={2}>
                  <Typography component="div" variant="subtitle2">
                    Kraftstoffverbrauch
                    {
                      consumptionSuffix === '*'
                        ? <span>*</span>
                        : <sup>{consumptionSuffix}</sup>
                    }
                  </Typography>
                  <Box my={1}>
                    <Divider />
                  </Box>
                  <CarDetailsList
                    items1={carDetails1}
                    items2={carDetails2}
                  />
                </Box>
              )}
            </Box>
            {prices && prices.length > 0 && (
              <Box
                mt={2}
                sx={{
                  backgroundColor: theme.palette.quaternary.main,
                  paddingX: theme.spacing(0),
                  paddingY: theme.spacing(2),
                  marginLeft: theme.spacing(-2),
                  marginRight: theme.spacing(-2),
                }}
              >
                {prices.map((priceRow, index) => {
                  const isLastRow = index === prices.length - 1;
                  const components = [];

                  if (index > 0
                    && !lastRowWasSummary
                    && priceRow.isSummary
                  ) {
                    lastRowWasSummary = true;
                    components.push(
                      <Box key="divider" my={1}>
                        <Divider />
                      </Box>,
                    );
                  }

                  components.push(
                    <Box
                      key={priceRow.id}
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      sx={{
                        backgroundColor: (index % 2 === 0 || isLastRow)
                          ? undefined
                          : 'rgba(0,0,0,0.05)',
                      }}
                    >
                      <Typography component="div" variant="subtitle2">
                        {priceRow.label}
                        <sup>
                          {priceRow.labelSuffix}
                        </sup>
                      </Typography>
                      <Typography
                        component="div"
                        sx={{
                          ml: 2,
                          whiteSpace: 'nowrap',
                          fontSize: isLastRow ? '1.15em' : undefined,
                        }}
                        variant="subtitle2"
                      >
                        {priceRow.value}
                        <sup>
                          {priceRow.valueSuffix}
                        </sup>
                      </Typography>
                    </Box>,
                  );

                  return components;
                })}
              </Box>
            )}
          </CardContent>
        </CardActionArea>
      </LinkElement>
      {buttons && buttons.length > 0 && (
        <CardActions>
          <Grid2 container spacing={1} sx={{ flex: 1 }}>
            {buttons.map((button) => (
              <Grid2
                key={button.id}
                size={{
                  sm: 6,
                  xs: 12,
                }}
              >
                <ButtonElement
                  color="primary"
                  data={button}
                  endIcon={<FiArrowRight />}
                  fullWidth
                  variant="contained"
                />
              </Grid2>
            ))}
          </Grid2>
        </CardActions>
      )}
    </Card>
  );
}

Car.propTypes = {
  item: PropTypes.object,
};

function CarDetailsList({
  items1,
  items2,
}) {
  const renderItems = (items) => items.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Grid2 key={index} alignItems="center" container spacing={1}>
      <Grid2 size={{ xs: 6 }}>
        <Typography component="div" variant="caption">
          {item.label}
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <Typography component="div" variant="caption">
          {item.value}
          {' '}
          {item.suffix}
        </Typography>
      </Grid2>
    </Grid2>
  ));

  return (
    <Grid2 container>
      {items1 && items1.length > 0 && (
        <Grid2 size={{
          sm: 6,
          xs: 6,
        }}
        >
          {renderItems(items1)}
        </Grid2>
      )}
      {items1 && items1.length > 0 && (
        <Grid2 size={{
          sm: 6,
          xs: 6,
        }}
        >
          {renderItems(items2)}
        </Grid2>
      )}
    </Grid2>
  );
}

CarPromotionBlock.typeName = 'ComponentContentCarPromotion'; // Strapi element type
CarPromotionBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    cars: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      consumptionInnercity: PropTypes.number,
      consumptionOutercity: PropTypes.number,
      consumptionCombined: PropTypes.number,
      consumptionCombinedElectricity: PropTypes.number,
      consumptionCombinedHydrogen: PropTypes.number,
      emissions: PropTypes.number,
      energyEfficiencyClass: PropTypes.string,
      consumptionSuffix: PropTypes.string,
      prices: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        labelSuffix: PropTypes.string,
        value: PropTypes.string,
        valueSuffix: PropTypes.string,
        isSummary: PropTypes.bool,
      })),
    })),
  }).isRequired,
};
CarPromotionBlock.dataSchema = yup.object()
  .shape({
    title: TypographyElement.dataSchema.nullable(),
    cars: yup.array()
      .of(yup.object()
        .shape({
          title: yup.string()
            .nullable(),
          subtitle: yup.string()
            .nullable(),
          consumptionInnercity: yup.number()
            .nullable(),
          consumptionOutercity: yup.number()
            .nullable(),
          consumptionCombined: yup.number()
            .nullable(),
          consumptionCombinedElectricity: yup.number()
            .nullable(),
          consumptionCombinedHydrogen: yup.number()
            .nullable(),
          emissions: yup.number()
            .nullable(),
          energyEfficiencyClass: yup.string()
            .nullable(),
          consumptionSuffix: yup.string()
            .nullable(),
          media: MediaElement.dataSchema.nullable(),
          link: LinkElement.dataSchema.nullable(),
          text: TypographyElement.dataSchema.nullable(),
          prices: yup.array()
            .of(yup.object()
              .shape({
                label: yup.string()
                  .nullable(),
                labelSuffix: yup.string()
                  .nullable(),
                value: yup.string()
                  .nullable(),
                valueSuffix: yup.string()
                  .nullable(),
                isSummary: yup.bool()
                  .nullable(),
              })),
          buttons: yup.array()
            .of(ButtonElement.dataSchema),
        })),
  });
CarPromotionBlock.graphQlSchema = `
... on ${CarPromotionBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  cars {
    id
    title
    subtitle
    consumptionInnercity
    consumptionOutercity
    consumptionCombined
    consumptionCombinedElectricity
    consumptionCombinedHydrogen
    emissions
    energyEfficiencyClass
    consumptionSuffix
    media {
      ${MediaElement.graphQlSchema}
    }
    link {
      ${LinkElement.graphQlSchema}
    }
    text {
      ${TypographyElement.graphQlSchema}
    }
    prices {
      id
      label
      labelSuffix
      value
      valueSuffix
      isSummary
    }
    buttons {
      ${ButtonElement.graphQlSchema}
    }
  }
}
`;
