import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useConsentManager } from '@lib/gdpr';
import { useTranslation } from 'react-i18next';
import { useContentElement } from '@plugins/next-cms-core';
import { Box, useTheme } from '@mui/material';
import Button from '../atoms/Button';
import Container from '../atoms/Container';

export default function ChangeGdprConsentBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const { changeConsent } = useConsentManager();

  // eslint-disable-next-line no-unused-vars
  useContentElement(
    data,
    ChangeGdprConsentBlock.dataSchema,
  );

  const handleChangeConsent = () => {
    changeConsent();
  };

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={handleChangeConsent}
          variant="contained"
        >
          {t('components.contentTypes.ChangeGdprConsentBlock.gdprConsentButton')}
        </Button>
      </Container>
    </Box>
  );
}

ChangeGdprConsentBlock.typeName = 'ComponentContentChangeGdprConsent'; // Strapi element type
ChangeGdprConsentBlock.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
};
ChangeGdprConsentBlock.dataSchema = yup.object().shape({

});
ChangeGdprConsentBlock.graphQlSchema = `
... on ${ChangeGdprConsentBlock.typeName} {
  id
}
`;
