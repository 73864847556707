import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid2,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import { FiCheck } from 'react-icons/fi';

export default function OptionList(props) {
  const {
    name,
    disabled,
    value,
    label,
    error,
    isMultiple,
    options,
    handleChange,
    setFieldTouched,
  } = props;
  const theme = useTheme();

  let columnSpacing = 6;
  if (options.length > 2 && options.length < 4) columnSpacing = 4;
  else if (options.length >= 4) columnSpacing = 3;

  const handleToggleItem = (newValue) => () => {
    if (disabled) {
      return;
    }

    setFieldTouched(name);

    if (isMultiple) {
      const fieldValues = value ?? [];

      if (fieldValues.includes(newValue)) {
        handleChange(fieldValues.filter((v) => v !== newValue));
      } else {
        handleChange([...fieldValues, newValue]);
      }
    } else {
      handleChange(newValue);
    }
  };

  return (
    <Box px={1}>
      {label && (
        <Typography variant="subtitle1">
          {label}
        </Typography>
      )}
      <Grid2 container spacing={3}>
        {options.map((item) => {
          const itemValue = item.value || item.label;
          const image = item.image?.data?.attributes?.formats?.medium?.url
                ?? item.image?.data?.attributes?.url;
          const isSelected = isMultiple
            ? (value ?? []).includes(itemValue)
            : value === itemValue;

          return (
            <Grid2 key={item.id} size={{ xs: columnSpacing }}>
              {image ? (
                <Box sx={{ position: 'relative' }}>
                  <Card
                    elevation={isSelected ? 20 : 1}
                    sx={{
                      borderWidth: 2,
                      borderStyle: 'solid',
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    <CardActionArea
                      onClick={handleToggleItem(itemValue)}
                    >
                      <CardMedia
                        component="img"
                        src={
                          item.image?.data?.attributes?.formats?.medium?.url
                              ?? item.image?.data?.attributes?.url
                        }
                      />
                      <CardContent sx={{ padding: 8 }}>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          textAlign="center"
                          variant="subtitle2"
                        >
                          {item.label}
                        </Typography>
                      </CardContent>
                      {/* Do not delete this <div /> */}
                      <div />
                      {item.badge && (
                        <Box
                          px={1}
                          py={0.25}
                        >
                          <Typography variant="caption">
                            {item.badge}
                          </Typography>
                        </Box>
                      )}
                    </CardActionArea>
                  </Card>
                  {isSelected && (
                    <Box
                      sx={{
                        position: 'absolute',
                        display: 'flex',
                        top: (24 / 2 - 4) * -1,
                        right: (24 / 2 - 4) * -1,
                        width: 24,
                        height: 24,
                        borderRadius: '50px',
                        backgroundColor: 'primary.main',
                        color: 'secondary.main',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FiCheck />
                    </Box>
                  )}
                </Box>
              ) : (
                <FormControlLabel
                  control={isMultiple ? (
                    <Checkbox
                      checked={isSelected}
                      onChange={handleToggleItem(itemValue)}
                    />
                  ) : (
                    <Radio
                      checked={isSelected}
                      color="primary"
                      onChange={handleToggleItem(itemValue)}
                    />
                  )}
                  label={item.label}
                />
              )}
            </Grid2>
          );
        })}
      </Grid2>
      {error && (
        <Box sx={{ mt: 2 }}>
          <FormHelperText error>
            {error}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
}
