import * as yup from 'yup';
import { Box, Grid2, useTheme } from '@mui/material';
import { MediaElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import Container from '@components/atoms/Container';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import StaffMember from '@components/molecules/StaffMember';

export default function StaffBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    StaffBlock.dataSchema,
  );

  const { employees } = elementData;

  let title = null;
  const items = employees.data ?? [];

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        py: theme.spacing(10),
      }}
    >
      <Container>
        {title && (
          <Box mb={7}>
            <TypographyElement align="center" data={title} />
          </Box>
        )}
        <Grid2
          container
          spacing={5}
          sx={{
            justifyContent: 'center',
          }}
        >
          {items.map((item) => (
            <Grid2 key={item.id} size={{ md: 3, sm: 4, xs: 6 }}>
              <Employee item={item} />
            </Grid2>
          ))}
        </Grid2>
      </Container>
    </Box>
  );
}

function Employee({ item }) {
  return (
    <StaffMember employee={item} variant="vertical" />
  );
}

StaffBlock.typeName = 'ComponentContentStaff'; // Strapi element type
StaffBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
  }).isRequired,
};
StaffBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema,
  employees: yup.object().shape({
    data: yup.array().of(yup.object().shape({
      id: yup.number().required(),
      attributes: yup.object().shape({
        name: yup.string().required(),
        position: yup.string().nullable(),
        phone: yup.string().nullable(),
        email: yup.string().nullable(),
        media: MediaElement.dataSchema,
      }),
    })),
  }).required(),
});
StaffBlock.graphQlSchema = `
... on ${StaffBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  employees(pagination: {limit: 100}) {
    data {
      id
      attributes {
        name
        position
        phone
        phoneWhatsapp
        email
        image {
          ${MediaElement.graphQlSchema}
        }
        primary_branch {
          data {
            attributes {
              name
              pageUrl
            }
          }
        }
        languages(sort: "name", pagination: {limit: 100}) {
          data {
            id
            attributes {
              name
              emoji
            }
          }
        }
      }
    }
  }
}
`;
