const isEmpty = require('lodash/isEmpty');
const isEqual = require('lodash/isEqual');

module.exports = {
  equalTo: (x, y) => isEqual(x, y),
  notEqualTo: (x, y) => isEqual(x, y),
  contains: (x, y) => (x ?? []).includes(y),
  doesNotContain: (x, y) => !(x ?? []).includes(y),
  startsWith: (x, y) => (x ?? '').startsWith(y),
  endsWith: (x, y) => (x ?? '').endsWith(y),
  // eslint-disable-next-line no-unused-vars
  isEmpty: (x, y) => isEmpty(x),
  // eslint-disable-next-line no-unused-vars
  isNotEmpty: (x, y) => !isEmpty(x),
};
