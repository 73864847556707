import * as yup from 'yup';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { Box, Paper, useTheme } from '@mui/material';
import { EmbedElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import dynamic from 'next/dynamic';
import Container from '../atoms/Container';

const EmbedElementDynamic = dynamic(() => import('@plugins/next-cms-core/components/elements/EmbedElement'), { ssr: false });

export default function EmbedBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    EmbedBlock.dataSchema,
  );

  const { embed } = elementData;
  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  return (
    <>
      <Box
        sx={{
          paddingTop: theme.spacing(10),
          paddingBottom: theme.spacing(10),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {title && (
          <Box sx={{ marginBottom: 5 }}>
            <TypographyElement
              align="center"
              data={title}
            />
          </Box>
          )}
          <Container sx={{ maxWidth: '800px !important' }}>
            <Paper elevation={18} square>
              <Box
                sx={{
                // Responsive embed
                  position: 'relative',
                  paddingBottom: '56.25%',
                  paddingTop: 0,
                  height: 0,
                }}
              >
                <EmbedElementDynamic
                  className="player"
                  data={embed}
                  options={{
                  // https://developers.google.com/youtube/player_parameters
                    modestbranding: 1,
                    rel: 0,
                  }}
                />
              </Box>
            </Paper>
          </Container>
        </Box>
      </Box>
      <style global jsx>
        {`

          .player, .player object, .player embed  {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

        `}
      </style>
    </>
  );
}

EmbedBlock.typeName = 'ComponentContentEmbed'; // Strapi element type
EmbedBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    embed: EmbedElement.propTypes,
  }).isRequired,
};
EmbedBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema.nullable(),
  embed: EmbedElement.dataSchema,
});
EmbedBlock.graphQlSchema = `
... on ${EmbedBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  embed {
    ${EmbedElement.graphQlSchema}
  }
}
`;
