import AccordionSliderBlock from '@components/contentTypes/AccordionSliderBlock';
import BlogBlock from '@components/contentTypes/BlogBlock';
import CampaignBlock from '@components/contentTypes/CampaignBlock';
import CarPartsShopBlock from '@components/contentTypes/CarPartsShopBlock';
import CarPartsShopStorefrontBlock from '@components/contentTypes/CarPartsShopStorefrontBlock';
import CarPromotionBlock from '@components/contentTypes/CarPromotionBlock';
import CarSearchFilterBlock from '@components/contentTypes/CarSearchFilterBlock';
import ChangeGdprConsentBlock from '@components/contentTypes/ChangeGdprConsentBlock';
import CustomerTestimonialsBlock from '@components/contentTypes/CustomerTestimonialsBlock';
import EmbedBlock from '@components/contentTypes/EmbedBlock';
import FaqBlock from '@components/contentTypes/FaqBlock';
import HeroBlock from '@components/contentTypes/HeroBlock';
import InventoryCarsBlock from '@components/contentTypes/InventoryCarsBlock';
import MapBlock from '@components/contentTypes/MapBlock';
import ModelRequestFormBlock from '@components/contentTypes/ModelRequestFormBlock';
import OnPageNavigationBlock from '@components/contentTypes/OnPageNavigationBlock';
import PageHeaderBlock from '@components/contentTypes/PageHeaderBlock';
import PublishDateBlock from '@components/contentTypes/PublishDateBlock';
import SoftgardenBlock from '@components/contentTypes/SoftgardenBlock';
import SoftgardenJobsBlock from '@components/contentTypes/SoftgardenJobsBlock';
import StaffBlock from '@components/contentTypes/StaffBlock';
import TextBlock from '@components/contentTypes/TextBlock';
import TextMediaBlock from '@components/contentTypes/TextMediaBlock';
import TilesBlock from '@components/contentTypes/TilesBlock';
import CountdownBlock from '@components/contentTypes/CountdownBlock';
import SimilarPagesBlock from '@components/contentTypes/SimilarPagesBlock';
import SingleDayRegistrationsBlock from '@components/contentTypes/SingleDayRegistrationsBlock';
import FrameBlock from '@components/contentTypes/FrameBlock';
import FormBlock from '@components/contentTypes/FormBlock';
import BranchBlock from '@components/contentTypes/BranchBlock';
import TextEmployeeBlock from '@components/contentTypes/TextEmployeeBlock';
import VehicleConfiguratorBlock from '@components/contentTypes/VehicleConfiguratorBlock';
import WorkshopAppointmentToolBlock from '@components/contentTypes/WorkshopAppointmentToolBlock';
import OnPageNavigationTargetBlock from '@components/contentTypes/OnPageNavigationTargetBlock';

export const components = [
  AccordionSliderBlock,
  BlogBlock,
  CarPartsShopBlock,
  CarPartsShopStorefrontBlock,
  CarPromotionBlock,
  CarSearchFilterBlock,
  CustomerTestimonialsBlock,
  EmbedBlock,
  FaqBlock,
  BranchBlock,
  FrameBlock,
  FormBlock,
  HeroBlock,
  InventoryCarsBlock,
  MapBlock,
  ModelRequestFormBlock,
  OnPageNavigationBlock,
  OnPageNavigationTargetBlock,
  PageHeaderBlock,
  SoftgardenBlock,
  SoftgardenJobsBlock,
  StaffBlock,
  SimilarPagesBlock,
  SingleDayRegistrationsBlock,
  TextBlock,
  TextMediaBlock,
  TextEmployeeBlock,
  TilesBlock,
  ChangeGdprConsentBlock,
  PublishDateBlock,
  CampaignBlock,
  VehicleConfiguratorBlock,
  WorkshopAppointmentToolBlock,
  CountdownBlock,
];
