import * as yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Grid2,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ColorScheme, LinkElement, MediaElement, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import Image from '../atoms/Image';
import Container from '../atoms/Container';
import { animationDefaults, easings, timings } from '../../theme';

export default function TilesBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    TilesBlock.dataSchema,
  );

  const {
    columns,
    tiles,
  } = elementData;
  let { colorScheme } = elementData;

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
    });
  }

  let columnSpacing = 0;
  const columnSpan = Math.floor(12 / columns);

  if (tiles.length < 3) {
    columnSpacing = 10;
  } else if (tiles.length < 5) {
    columnSpacing = 7;
  } else {
    columnSpacing = 3;
  }

  colorScheme = ColorScheme.getColorScheme(colorScheme, 'default');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        backgroundColor: getRootBackgroundColor(cssColorClass, theme),
      }}
    >
      <Container>
        {title && (
          <TypographyElement
            align="center"
            data={title}
            sx={{ mb: 5 }}
          />
        )}
        <Grid2
          alignItems="stretch"
          container
          justifyContent="center"
          spacing={columnSpacing}
        >
          {tiles.map((tile) => (
            <Grid2
              key={tile.id}
              size={{
                sm: columnSpan,
                xs: 12,
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Tile item={tile} theme={theme} />
            </Grid2>
          ))}
        </Grid2>
      </Container>
    </Box>
  );
}

function Tile(props) {
  const {
    item,
    theme,
  } = props;
  const markedItems = ['45', '46', '47', '48', '1077', '1078', '1079', '1080', '819', '820', '821', '822', '561', '562', '563', '564', '1293', '1294', '1295', '1296'];

  const {
    display,
    textOverMediaPosition,
    textOverMediaColor,
    bottomPriceBadge,
  } = item;

  const isMediaSvg = Boolean(item.media?.file.data.attributes.url.endsWith('.svg'));
  let text = null;
  let textOverMedia = null;

  if (item.text && item.text.value) {
    text = omitBy(item.text, isNull);
    defaults(text, {
      semanticVariant: 'div',
      displayVariant: 'body2',
      textAlign: 'justify',
    });
  }

  if (item.textOverMedia && item.textOverMedia.value) {
    textOverMedia = omitBy(item.textOverMedia, isNull);
    defaults(textOverMedia, {
      semanticVariant: 'div',
      displayVariant: 'body2',
      textAlign: 'justify',
    });
  }

  return (
    <Card
      elevation={3}
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        transition: `all ${timings.slowAnimationTiming}ms ${easings.defaultEasingCss}`,
        '&:hover': {
          transform: `scale(${animationDefaults.defaultScale})`,
        },
        backgroundColor: display === 'button' ? theme.palette.primary.main : 'inherit',
      }}
    >
      <LinkElement data={item.link}>
        <CardActionArea
          component="a"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            textDecoration: 'none !important',
          }}
        >
          {display !== 'button' && (
            <>
              {item.media && (
                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  <MediaElement
                    data={item.media}
                    isFluid
                    style={{
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      height: '100%',
                    }}
                  />
                  {textOverMedia && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        padding: theme.spacing(1),
                        justifyContent: getTextOverMediaPosition(textOverMediaPosition),
                      }}
                    >
                      <TypographyElement
                        color={textOverMediaColor ?? 'initial'}
                        data={textOverMedia}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {(item.title || text || item.buttonLabel || bottomPriceBadge) && (
                <CardContent
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  {(item.title || text) && (
                    <Box flex={1}>
                      {item.title && (
                        <Box mb={2}>
                          <Typography variant="subtitle1">
                            {item.title}
                          </Typography>
                        </Box>
                      )}
                      {text && (
                        <Box mb={3}>
                          <TypographyElement
                            data={text}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                  {item.buttonLabel && (
                    <Button
                      color="primary"
                      fullWidth
                      variant="outlined"
                    >
                      {item.buttonLabel}
                    </Button>
                  )}
                </CardContent>
              )}
              {markedItems.includes(item.id) && (
                <Grid2 container spacing={4}>
                  <Grid2 size={{ xs: 1 }} />
                  <Grid2 size={{ xs: 2 }}>
                    <Image
                      alt="Hyundai"
                      isFluid
                      src="/images/car-brands/hyundai-motor-company-logo-2.svg"
                      styles={{
                        marginBottom: theme.spacing(2),
                        width: '100%',
                      }}
                    />
                  </Grid2>
                  <Grid2
                    size={{ xs: 2 }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      alt="MG Motor"
                      isFluid
                      src="/images/car-brands/mgmotors-logo.svg"
                      styles={{
                        marginBottom: theme.spacing(2),
                        width: 105,
                      }}
                    />
                  </Grid2>
                  <Grid2
                    size={{ xs: 2 }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      alt="Mitsubishi"
                      isFluid
                      src="/images/car-brands/mitsubishi-logo.svg"
                      styles={{
                        marginBottom: theme.spacing(2),
                        width: 105,
                      }}
                    />
                  </Grid2>
                  <Grid2
                    size={{ xs: 2 }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      alt="FUSO"
                      isFluid
                      src="/images/car-brands/mitsubishi-fuso-logo.svg"
                      styles={{
                        marginBottom: theme.spacing(2),
                        width: 105,
                      }}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 2 }}>
                    <Image
                      alt="Maxus"
                      isFluid
                      src="/images/car-brands/maxus-logo.png"
                      styles={{
                        marginBottom: theme.spacing(2),
                        width: '100%',
                      }}
                    />
                  </Grid2>
                </Grid2>
              )}
              {bottomPriceBadge && (
                <CardActions>
                  <Box flex={1} p={1}>
                    <Box mb={1}>
                      <Divider />
                    </Box>
                    <Typography
                      align="right"
                      color="primary"
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: bottomPriceBadge,
                      }}
                      variant="h4"
                    />
                  </Box>
                </CardActions>
              )}
            </>
          )}
          {display === 'button' && (item.media || item.buttonLabel) && (
            <CardContent
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                // aspectRatio: '3 / 2', Error in layout
                minHeight: 150,
              }}
            >
              {item.media && (
                <MediaElement
                  data={item.media}
                  isFluid={!isMediaSvg}
                  isMediaSvg={isMediaSvg}
                  style={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    marginBottom: theme.spacing(2),
                    ...(isMediaSvg && getTileMediaSvg(item.media.scaling ?? 'normal')),
                  }}
                />
              )}
              {item.buttonLabel && (
                <Typography align="center" color="secondary" variant="button">
                  {item.buttonLabel}
                </Typography>
              )}
            </CardContent>
          )}
        </CardActionArea>
      </LinkElement>
    </Card>
  );
}

Tile.propTypes = {
  item: PropTypes.object,
};

function getRootBackgroundColor(variant, theme) {
  switch (variant) {
    case 'Primary':
      return theme.palette.secondary.main;
    case 'Secondary':
      return theme.palette.primary.main;
    case 'Tertiary':
      return theme.palette.tertiary.main;
    case 'Quaternary':
      return theme.palette.quaternary.main;
    case 'Light':
      return theme.palette.light.main;
    case 'Dark':
      return theme.palette.dark.main;
    case 'MitsubishiYellow':
      return '#f5ba45';
    default:
      return 'transparent';
  }
}

function getTextOverMediaPosition(textOverMediaPosition) {
  switch (textOverMediaPosition) {
    case 'top':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'bottom':
      return 'flex-end';
    default:
      return 'flex-start';
  }
}

function getTileMediaSvg(mediaScaling) {
  switch (mediaScaling) {
    case 'tiny':
      return {
        width: 16,
        height: 16,
      };
    case 'small':
      return {
        width: 22,
        height: 22,
      };
    case 'smaller':
      return {
        width: 38,
        height: 38,
      };
    case 'larger':
      return {
        width: 80,
        height: 80,
      };
    case 'large':
      return {
        width: 120,
        height: 120,
      };
    case 'big':
      return {
        width: 160,
        height: 160,
      };
    default:
      return {
        width: 60,
        height: 60,
      };
  }
}

TilesBlock.typeName = 'ComponentContentTiles'; // Strapi element type
TilesBlock.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.number,
    title: TypographyElement.propTypes,
    tiles: PropTypes.arrayOf(PropTypes.shape({
      display: PropTypes.oneOf([null, 'button', 'card']),
      button_label: PropTypes.string,
      title: PropTypes.string,
      text: TypographyElement.propTypes,
      link: LinkElement.propTypes.isRequired,
      media: MediaElement.propTypes,
      text_over_media: TypographyElement.propTypes,
      textOverMediaPosition: PropTypes.string,
      textOverMediaColor: PropTypes.string,
      bottomPriceBadge: PropTypes.string,
    })),
    color_scheme: ColorScheme.propTypes,
  }).isRequired,
};

TilesBlock.dataSchema = yup.object()
  .shape({
    columns: yup.number()
      .nullable(),
    title: TypographyElement.dataSchema.nullable(),
    tiles: yup.array()
      .of(yup.object()
        .shape({
          display: yup.string()
            .oneOf([null, 'button', 'card'])
            .nullable(),
          button_label: yup.string()
            .nullable(),
          title: yup.string()
            .nullable(),
          text: TypographyElement.dataSchema.nullable(),
          link: LinkElement.dataSchema.required(),
          media: MediaElement.dataSchema.nullable(),
          text_over_media: TypographyElement.dataSchema.nullable(),
          textOverMediaPosition: yup.string()
            .nullable(),
          textOverMediaColor: yup.string()
            .nullable(),
          bottomPriceBadge: TypographyElement.dataSchema.nullable(),
        })),
    color_scheme: ColorScheme.dataSchema.nullable(),
  });
TilesBlock.graphQlSchema = `
... on ${TilesBlock.typeName} {
  id
  columns
  title {
    ${TypographyElement.graphQlSchema}
  }
  tiles(pagination: {limit: 100}) {
    id
    display
    buttonLabel
    title
    text {
      ${TypographyElement.graphQlSchema}
    }
    link {
      ${LinkElement.graphQlSchema}
    }
    media {
      ${MediaElement.graphQlSchema}
    }
    textOverMedia {
      ${TypographyElement.graphQlSchema}
    }
    textOverMediaPosition
    textOverMediaColor
    bottomPriceBadge
  }
  colorScheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;
