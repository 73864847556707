/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid2,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FUEL_TYPE, TRANSMISSION_TYPE } from '@plugins/vehiclefleetapp';
import { FiArrowRight, FiSearch } from 'react-icons/fi';
import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import React, { useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import { getCarSearchRoute } from '@lib/routing';
import { isClientSide } from '@lib/utils/environment';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Slider from '@components/atoms/Slider';
import Link from '../atoms/Link';
import Select from '../atoms/Select';
import Container from '../atoms/Container';
import InventoryCarListItem, { GRAPHQL_REQUIRED_PROPS } from '../vehiclefleet/InventoryCarListItem';

const PAGE_QUERY_PARAM = 'isp';

export default function InventoryCarsBlock(props) {
  const { data } = props;
  const router = useRouter();
  const theme = useTheme();
  const {
    i18n,
    t,
  } = useTranslation();
  const isInitialMount = useRef(true);
  const [swiper, setSwiper] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const take = useItemPreviewCount();
  const [sort, setSort] = useState(SORT_OPTIONS[0].value);
  const [fuelType, setFuelType] = useState(null);
  const [transmission, setTransmission] = useState(null);

  const { elementData } = useContentElement(
    data,
    InventoryCarsBlock.dataSchema,
  );

  const {
    includeDummyCars,
    branches,
    inventoryCarFilters: filters,
    inventoryCarFilterGroups: filterGroups,
  } = elementData;

  const title = elementData.title ? omitBy(elementData.title, isNull) : null;

  if (title) {
    defaults(title, {
      textAlign: 'center',
      semanticVariant: 'h3',
      displayVariant: 'h3',
    });
  }

  // Additional data
  const {
    error: filterQueryError,
    data: filterData,
  } = useQuery(QUERY_CAR_SEARCH_FILTER_DATA, {
    variables: {},
  });

  const queryCarFilters = filterGroups.length > 0
    ? filterGroups.map((group) => ({
      filters: group.filters.map((filter) => ({
        condition: filter.condition,
        type: filter.type,
        value: filter.value,
      })),
    }))
    : [
      {
        filters: filters.map((filter) => ({
          condition: filter.condition,
          type: filter.type,
          value: filter.value,
        })),
      },
    ];

  const {
    error: additionalDataQueryError,
    data: additionalData,
    loading,
    networkStatus,
  } = useQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        skip: 0,
        take: 100,
        sort,
        branches: branches?.data?.map((x) => parseInt(x.id, 10)) ?? [],
        filterGroups: queryCarFilters,
        options: {
          dummy: includeDummyCars,
          fuelType: fuelType || null,
          transmission: transmission || null,
        },
      },
    },
    onCompleted: () => {
      const initialSlide = getInitialSlide();

      if (initialSlide > 0) {
        swiper.slideTo(initialSlide);
      }
    },
  });

  const slideIndex = parseInt(router.query[PAGE_QUERY_PARAM] ?? 0, 10);

  useEffect(() => {
    if (!swiper || !additionalData) return;
    swiper.slideTo(slideIndex);
  }, [slideIndex, swiper, additionalData]);

  if (filterQueryError || additionalDataQueryError) {
    throw filterQueryError ?? additionalDataQueryError;
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  });

  useEffect(() => {
    if (isInitialMount.current) {
      return;
    }

    if (isNil(additionalData?.filteredCarsData.totalCount)) {
      return;
    }

    setTotalCount(additionalData?.filteredCarsData.totalCount);
  }, [additionalData?.filteredCarsData.totalCount]);

  const isLoading = loading || networkStatus < NetworkStatus.ready;
  const items = additionalData?.filteredCarsData.items ?? [];
  const isPurchasingEnabled = Boolean(additionalData?.carSearchConfig?.isPurchasingEnabled);

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };
  const handleChangeFuelType = (event) => {
    setFuelType(event.target.value);
  };
  const handleChangeTransmission = (event) => {
    setTransmission(event.target.value);
  };

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
      }}
    >
      <Container>
        {title && (
          <Box
            sx={{
              paddingBottom: theme.spacing(5),
            }}
          >
            <TypographyElement align="center" data={title} />
          </Box>
        )}
        <Box>
          <Box mb={3}>
            <FilterBar
              data={filterData?.carSearchFilterData}
              fuelType={fuelType}
              i18n={i18n}
              onChangeFuelType={handleChangeFuelType}
              onChangeSort={handleChangeSort}
              onChangeTransmission={handleChangeTransmission}
              sort={sort}
              t={t}
              transmission={transmission}
            />
          </Box>
          <Box sx={{ position: 'relative' }}>
            {totalCount === 0 && (
              <EmptyResultSet t={t} />
            )}
            {isLoading && (
              <LoadingState count={take} />
            )}
            <Slider
              count={take}
              items={items}
              pageQueryParam={PAGE_QUERY_PARAM}
              setSwiper={setSwiper}
              SlideComponent={InventoryCarListItem}
              slideComponentProps={{
                isPurchasingEnabled,
              }}
              sliderStyles={{
                padding: 7,
                margin: -7,
                paddingTop: 20,
              }}
              swiper={swiper}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function EmptyResultSet({ t }) {
  const theme = useTheme();
  return (
    <Box alignItems="center" display="flex" justifyContent="center" pb={10} pt={5}>
      <Box mr={1}>
        <FiSearch
          style={{
            color: theme.palette.text.primary,
            opacity: 0.75,
          }}
        />
      </Box>
      <Typography
        sx={{
          color: theme.palette.text.primary,
          opacity: 0.75,
        }}
      >
        {t('components.contentTypes.InventoryCarsBlock.emptyResultSet')}
      </Typography>
    </Box>
  );
}

function LoadingState({ count }) {
  return (
    <Grid2
      container
      spacing={5}
      sx={{
        alignItems: 'stretch',
      }}
    >
      {Array.from(Array(count)
        .keys())
        .map((_, index) => (
          <Grid2
            key={index}
            size={{ xs: (12 / count) }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <InventoryCarListItem useSkeleton />
          </Grid2>
        ))}
    </Grid2>
  );
}

function FilterBar(props) {
  const {
    t,
    i18n,
    data,
    sort,
    fuelType,
    transmission,
    onChangeSort,
    onChangeFuelType,
    onChangeTransmission,
  } = props;
  const columnConfig = {
    xs: 12,
    sm: 6,
    md: 3,
    lg: 3,
  };

  const {
    fuelTypes = [],
    transmissions = [],
  } = data ?? {};

  return (
    <Grid2 alignItems="center" container spacing={3}>
      <Grid2 size={columnConfig}>
        <Select
          label={t('components.contentTypes.InventoryCarsBlock.sorting')}
          onChange={onChangeSort}
          size="small"
          value={sort}
          variant="outlined"
        >
          {SORT_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option[`label_${i18n.language}`]}
            </MenuItem>
          ))}
        </Select>
      </Grid2>
      <Grid2 size={columnConfig}>
        <Select
          label={t('components.contentTypes.InventoryCarsBlock.fuel')}
          onChange={onChangeFuelType}
          size="small"
          value={fuelType ?? ''}
          variant="outlined"
        >
          <MenuItem value="">
            {t('components.contentTypes.InventoryCarsBlock.allFuels')}
          </MenuItem>
          {fuelTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {FUEL_TYPE[option]}
            </MenuItem>
          ))}
        </Select>
      </Grid2>
      <Grid2 size={columnConfig}>
        <Select
          label={t('components.contentTypes.InventoryCarsBlock.transmission')}
          onChange={onChangeTransmission}
          size="small"
          value={transmission ?? ''}
          variant="outlined"
        >
          <MenuItem value="">
            {t('components.contentTypes.InventoryCarsBlock.allTransmissions')}
          </MenuItem>
          {transmissions.map((option) => (
            <MenuItem key={option} value={option}>
              {TRANSMISSION_TYPE[option]}
            </MenuItem>
          ))}
        </Select>
      </Grid2>
      <Grid2 size={columnConfig}>
        <Link href={getCarSearchRoute(i18n.language)}>
          <Button
            color="primary"
            component="a"
            endIcon={<FiArrowRight />}
            fullWidth
          >
            {t('components.contentTypes.InventoryCarsBlock.detailedSearch')}
          </Button>
        </Link>
      </Grid2>
    </Grid2>
  );
}

function useItemPreviewCount() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLg = useMediaQuery(theme.breakpoints.up('md'));

  let previewCount = 1;

  if (isLg) {
    previewCount = 3;
  } else if (isSm) {
    previewCount = 2;
  }

  return previewCount;
}

InventoryCarsBlock.typeName = 'ComponentContentInventoryCars'; // Strapi element type
InventoryCarsBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    inventoryCarFilters: PropTypes.arrayOf(PropTypes.shape({
      condition: PropTypes.oneOf(['Include', 'Exclude']).isRequired,
      type: PropTypes.oneOf(['Brand', 'Model', 'Search']).isRequired,
      value: PropTypes.string.isRequired,
    })),
  }).isRequired,
};
InventoryCarsBlock.dataSchema = yup.object()
  .shape({
    title: TypographyElement.dataSchema.nullable(),
    inventoryCarFilters: yup.array()
      .of(yup.object()
        .shape({
          condition: yup.string()
            .required()
            .oneOf(['Include', 'Exclude']),
          type: yup.string()
            .required(),
          value: yup.string()
            .required(),
        })),
  });
InventoryCarsBlock.graphQlSchema = `
... on ${InventoryCarsBlock.typeName} {
  id
  includeDummyCars
  title {
    ${TypographyElement.graphQlSchema}
  }
  branches {
    data {
      id
    }
  }
  inventoryCarFilters: filters {
    condition
    type
    value
  }
  inventoryCarFilterGroups: filterGroups {
    filters {
      condition
      type
      value
    }
  }
}
`;

function getInitialSlide() {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(PAGE_QUERY_PARAM)) {
    return parseInt(queryParams.get(PAGE_QUERY_PARAM) ?? 0, 10);
  }
  return 0;
}

const FETCH_ADDITIONAL_DATA = gql`
  query GetInventoryCars($input: FilteredCarsInput!) {
    filteredCarsData(input: $input) {
      items {
        ${GRAPHQL_REQUIRED_PROPS}
      }
      totalCount
    }
    carSearchConfig {
      data {
        attributes {
          isPurchasingEnabled
        }
      }
    }
  }
`;

export const QUERY_CAR_SEARCH_FILTER_DATA = gql`
  query GetSearchFilterData($fuelType: String, $transmission: String) {
    carSearchFilterData(fuelType: $fuelType, transmission: $transmission) {
      fuelTypes
      fuelType
      transmissions
      transmission
    }
  }
`;

const SORT_OPTIONS = [
  {
    value: 'is_immediately_available:desc,price:asc',
    label_de: 'Verfügbarkeit',
    label_en: 'Availability',
  },
  {
    value: 'price:asc',
    label_de: 'Preis aufsteigend',
    label_en: 'Price ascending',
  },
  {
    value: 'price:desc',
    label_de: 'Preis absteigend',
    label_en: 'Price descending',
  },
];
