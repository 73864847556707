/* eslint-disable no-underscore-dangle */
import * as yup from 'yup';
import {
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  useTheme,
  Box,
} from '@mui/material';
import {
  FiArrowRight,
  FiChevronDown,
  FiChevronRight,
} from 'react-icons/fi';
import PropTypes from 'prop-types';
import { useContentElement } from '@plugins/next-cms-core';
import { useState } from 'react';
import Hidden from '@components/atoms/Hidden';
import Container from '@components/atoms/Container';
import OnPageNavigationTargetBlock, { getAnchorRefName } from './OnPageNavigationTargetBlock';

export default function OnPageNavigationBlock(props) {
  const { data, blocks } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    OnPageNavigationBlock.dataSchema,
  );

  const { isVisible } = elementData;

  if (!isVisible) {
    return null;
  }

  const navigationTargets = blocks
    .filter((block) => block.__typename === OnPageNavigationTargetBlock.typeName);

  return (
    <Hidden dir="up" size="md">
      <Box
        sx={{
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3),
        }}
      >
        <Container>
          <Paper variant="outlined">
            <MobileMenuContainer items={navigationTargets} />
            {/* <Hidden dir="down" size="sm">
              <DesktopMenuContainer items={navigationTargets} />
            </Hidden> */}
          </Paper>
        </Container>
      </Box>
    </Hidden>
  );
}

function MobileMenuContainer({ items }) {
  const [isExpanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(false);
  };

  return (
    <List component="div" disablePadding>
      <ListItem button onClick={() => setExpanded(!isExpanded)}>
        <ListItemIcon>
          {isExpanded
            ? <FiChevronDown />
            : <FiChevronRight />}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: 'subtitle2',
          }}
        >
          Springe zu
        </ListItemText>
      </ListItem>
      <Collapse in={isExpanded}>
        <Divider />
        {items.map(({ id, label, anchorName }) => (
          <ListItem
            key={id}
            component="a"
            href={`#scroll-${getAnchorRefName(id, anchorName)}`}
            onClick={handleClick}
          >
            <ListItemIcon>
              <FiArrowRight />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
              }}
            >
              {label}
            </ListItemText>
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
}

function DesktopMenuContainer({ items }) {
  const useSpacing = items.length >= 5;

  return (
    <Toolbar
      sx={{
        justifyContent: useSpacing ? 'space-between' : 'inherit',
      }}
      variant="dense"
    >
      {items.map(({ id, label, anchorName }, index) => (
        <Button
          key={id}
          component="a"
          href={`#${getAnchorRefName(id, anchorName)}`}
          startIcon={index === 0 ? <FiChevronRight /> : null}
        >
          {label}
        </Button>
      ))}
    </Toolbar>
  );
}

OnPageNavigationBlock.typeName = 'ComponentContentOnPageNavigation'; // Strapi element type
OnPageNavigationBlock.propTypes = {
  data: PropTypes.shape({
    isVisible: PropTypes.bool.isRequired,
  }).isRequired,
  blocks: PropTypes.array.isRequired,
};
OnPageNavigationBlock.dataSchema = yup.object().shape({
  isVisible: yup.bool().required(),
});
OnPageNavigationBlock.graphQlSchema = `
... on ${OnPageNavigationBlock.typeName} {
  id
  isVisible
}
`;
