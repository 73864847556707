import PropTypes from 'prop-types';
import { selectField } from '@lib/form';
import DropDown from './DropDown';
import OptionList from './OptionList';

export default function SelectField(props) {
  const { variant } = props;

  const Component = VARIANTS[variant || 'dropDown'];

  if (!Component) {
    throw new Error(`Select variant "${variant}" not supported.`);
  }

  return <Component {...props} />;
}

const VARIANTS = {
  dropDown: DropDown,
  optionList: OptionList,
};

SelectField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
};

SelectField.getDefaultValue = selectField.getDefaultValue;
SelectField.serializeValue = selectField.serializeValue;
SelectField.deserializeValue = selectField.deserializeValue;
SelectField.getDisplayValue = selectField.getDisplayValue;
SelectField.validationSchema = selectField.validationSchema;
SelectField.graphQlSchema = `
typeSelect {
  id
  isMultiple
  minOptions
  maxOptions
  variant
  options(pagination: { limit: 100 }) {
    id
    label
    value
    badge
    image {
      data {
        id
        attributes {
          url
          formats
          hash
          height
          width
          name
          previewUrl
        }
      }
    }
  }
}
`;
