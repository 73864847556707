import * as yup from 'yup';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';
import WorkshopAppointmentTool from '../organisms/WorkshopAppointmentTool';
import { BRANCH_PROPERTIES } from '../organisms/WorkshopAppointmentTool/steps/Branches';

export default function WorkshopAppointmentToolBlock(props) {
  const { data } = props;
  const { elementData } = useContentElement(
    data,
    WorkshopAppointmentToolBlock.dataSchema,
  );

  const { branch } = data;
  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <Box>
      {title && (
        <Container>
          <Box
            sx={{
              marginBottom: 3,
              marginTop: 5,
            }}
          >
            <TypographyElement align="center" data={title} />
          </Box>
        </Container>
      )}
      <WorkshopAppointmentTool branch={branch?.data} />
    </Box>
  );
}

WorkshopAppointmentToolBlock.typeName = 'ComponentContentWorkshopappointmenttool'; // Strapi element type
WorkshopAppointmentToolBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
  }).isRequired,
};

WorkshopAppointmentToolBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema.nullable(),
});

WorkshopAppointmentToolBlock.graphQlSchema = `
... on ${WorkshopAppointmentToolBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  branch {
    data {
      ${BRANCH_PROPERTIES}
    }
  }
}
`;
