const yup = require('yup');

function getDefaultValue() {
  return null;
}

function deserializeValue(state, { type }, { DateTime }) {
  if (!state.value) {
    return getDefaultValue();
  }

  switch (state.type ?? type) {
    case 'time':
      return DateTime.fromISO(state.value);
    case 'date':
      return DateTime.fromISO(state.value);
    case 'datetime':
      return DateTime.fromISO(state.value);
    default:
      throw new Error(`[DateTimeField] Type '${type}' not supported.`);
  }
}

module.exports = {
  getDefaultValue,
  serializeValue: (value, { type }) => {
    let serializedValue = getDefaultValue();

    if (value) {
      switch (type) {
        case 'time':
          serializedValue = value.toISOTime()?.substring(0, 8);
          break;
        case 'date':
          serializedValue = value.toISODate();
          break;
        case 'datetime':
          serializedValue = value.toISO();
          break;
        default:
          throw new Error(`[DateTimeField] Type '${type}' not supported.`);
      }
    }

    return {
      value: serializedValue,
      type,
    };
  },
  deserializeValue,
  getDisplayValue: (...args) => {
    const { type } = args[1];
    const { DateTime } = args[2];
    const value = deserializeValue(...args)?.setLocale('de');

    if (!value) {
      return '-';
    }

    switch (type) {
      case 'time':
        return value.toLocaleString(DateTime.TIME_24_SIMPLE);
      case 'date':
        return value.toLocaleString(DateTime.DATE_SHORT);
      case 'datetime':
        return value.toLocaleString(DateTime.DATETIME_SHORT);
      default:
        return '';
    }
  },
  validationSchema: () => yup
    .string()
    .nullable(),
};
