/* eslint-disable no-restricted-syntax */

const { getFieldType } = require('./helper');

function getInitialFormValues(form, formValues, formFieldMapping, dependencies, queries) {
  const state = {
    field_name_refs: {},
  };

  for (const group of form.items) {
    for (const field of group.fields) {
      if (field.isHidden) {
        continue;
      }
      const fieldId = field.id;
      const fieldName = field.name;
      const key = `field_${fieldId}`;
      // eslint-disable-next-line no-underscore-dangle
      const formFieldType = getFieldType(field)?.__typename;
      const formFieldConfig = getFieldType(field);

      if (!formFieldType) {
        // Meta information
        state[`${key}_meta_fieldId`] = fieldId;
        state[`${key}_meta_type`] = 'unknown';
        state[`${key}_meta_name`] = fieldName;
        state[`${key}_meta_defaultValue`] = null;
        state[`${key}_meta_hasValue`] = false;
        state[`${key}_meta_label`] = field.label;
        state[`${key}_meta_optional`] = !field.isRequired;
        // Used for conditional logic
        state.field_name_refs[fieldName] = key;
        continue;
      }

      const context = {
        key,
        values: state,
      };
      const formValue = formValues.find((fv) => fv.attributes.fieldId === fieldId);
      const defaultValue = queries[fieldName] ?? formFieldMapping[formFieldType]
        .getDefaultValue(formFieldConfig, dependencies, context);
      let serializedState = formValue?.attributes?.value;

      if (!serializedState) {
        serializedState = formFieldMapping[formFieldType]
          .serializeValue(defaultValue, formFieldConfig, dependencies, context);
      }

      state[key] = formFieldMapping[formFieldType]
        .deserializeValue(serializedState, formFieldConfig, dependencies, context);
      state[`${key}_meta_displayValue`] = formFieldMapping[formFieldType]
        .getDisplayValue(serializedState, formFieldConfig, dependencies, context);
      state[`${key}_meta_internalValue`] = formFieldMapping[formFieldType]
        .getDisplayValue(serializedState, {
          ...formFieldConfig,
          showInternalValue: true,
        }, dependencies, context);

      if (!queries[fieldName] && formFieldConfig.preFilledValue) {
        state[key] = formFieldConfig.preFilledValue;
      }

      // Meta information
      state[`${key}_meta_fieldId`] = fieldId;
      state[`${key}_meta_type`] = formFieldType;
      state[`${key}_meta_name`] = fieldName;
      state[`${key}_meta_defaultValue`] = defaultValue;
      state[`${key}_meta_hasValue`] = false;
      state[`${key}_meta_label`] = field.label;
      state[`${key}_meta_optional`] = !field.isRequired;
      // Used for conditional logic
      state.field_name_refs[fieldName] = key;
    }
  }

  return state;
}

module.exports = getInitialFormValues;
