/* eslint-disable no-restricted-syntax */
const compareFunctions = require('./compareFunctions');

function getFormDisplayState(form, values) {
  const displayState = {
    fieldRefs: {},
  };

  for (const group of form.items) {
    const groupKey = `group_${group.id}`;
    displayState[groupKey] = {
      id: group.id,
      isVisible: true,
    };

    if (group.isConditional) {
      displayState[groupKey].isVisible = checkConditionalState(
        displayState,
        values,
        group.visibility,
        group.matches,
        group.rules,
      );
    }

    for (const field of group.fields) {
      const fieldId = field.id;
      const fieldKey = `field_${fieldId}`;

      displayState[fieldKey] = {
        id: field.id,
        fieldId: field.id,
        groupKey,
        isVisible: displayState[groupKey].isVisible,
      };

      if (displayState[fieldKey].isVisible && field.isConditional) {
        displayState[fieldKey].isVisible = checkConditionalState(
          displayState,
          values,
          field.visibility,
          field.matches,
          field.rules,
        );
      }

      displayState.fieldRefs[field.name] = displayState[fieldKey].isVisible;
    }
  }

  return displayState;
}

function checkConditionalState(displayState, values, visibility, matches, rules) {
  const ruleMatches = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const rule of rules) {
    const compareFn = rule.compare ?? 'equalTo';

    if (!compareFunctions[compareFn]) {
      throw new Error(`Compare function '${compareFn} not supported.`);
    }

    if (displayState.fieldRefs[rule.field] === false) {
      ruleMatches.push(false);
      // eslint-disable-next-line no-continue
      continue;
    }

    const value = values[values.field_name_refs[rule.field]];
    const result = compareFunctions[compareFn](value, rule.value);
    ruleMatches.push(result);
  }

  if (matches === 'all') {
    const matchesAll = !ruleMatches.some((m) => !m);
    return visibility === 'hide' ? !matchesAll : matchesAll;
  }

  const matchesAny = ruleMatches.some((m) => m);
  return visibility === 'hide' ? !matchesAny : matchesAny;
}

module.exports = getFormDisplayState;
