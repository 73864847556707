import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@mui/material/styles';

export default function EmissionLabel(props) {
  const { efficiency } = props;
  const StyledLabel = getStyledLabel(efficiency);

  return (
    <StyledLabel>
      <span>{efficiency}</span>
    </StyledLabel>
  );
}

EmissionLabel.propTypes = {
  efficiency: PropTypes.oneOf([
    'A++++',
    'A+++',
    'A++',
    'A+',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
  ]).isRequired,
};

// Utility to define styled component based on efficiency level
const getStyledLabel = (efficiency) => {
  const baseStyles = {
    position: 'relative',
    display: 'inline-block',
    color: 'white',
    height: 18,
    lineHeight: '18px',
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
    fontWeight: '500', // Adjust based on the theme's typography
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    '&::after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      width: 0,
      height: 0,
      right: -9,
      borderStyle: 'solid',
      borderWidth: '9px 0 9px 9px',
      borderColor: 'transparent transparent transparent white',
    },
  };

  const efficiencyColors = {
    'A++++': '#00a54f',
    'A+++': '#00a54f',
    'A++': '#00a54f',
    'A+': '#00a54f',
    A: '#00a54f',
    B: '#4cb848',
    C: '#bed630',
    D: '#fff101',
    E: '#fcb814',
    F: '#f36e21',
    G: '#ee1d23',
  };

  return styled('span')({
    ...baseStyles,
    backgroundColor: efficiencyColors[efficiency],
    '&::after': {
      borderColor: `transparent transparent transparent ${efficiencyColors[efficiency]}`,
    },
  });
};
