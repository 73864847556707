import { Box, Typography } from '@mui/material';
import Link from '@components/atoms/Link';

export default function FormFooter() {
  return (
    <Box
      mt={3}
      textAlign="center"
    >
      <Typography>
        Die Hinweise zum
        {' '}
        <Link href="/datenschutz" legacyBehavior>
          <a target="_blank">
            Datenschutz
          </a>
        </Link>
        {' '}
        habe ich zur Kenntnis genommen.
      </Typography>
    </Box>
  );
}
