import * as yup from 'yup';
import {
  Box, Button, Grid2, MenuItem, Paper, Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Formik } from 'formik';
import TextInput from '@components/atoms/TextInput';
import { formikInjectedPropsTypes } from '@forms/propTypes';
import getConfig from 'next/config';
import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import Select from '../../../atoms/Select';

const { publicRuntimeConfig } = getConfig();

export default function PersonalDetails(props) {
  const {
    t,
    data,
    setData,
    onGoToConfirmationPage,
  } = props;

  const handleSelectCustomerOption = (event) => {
    setData({
      ...data,
      existingCustomerOption: event.target.value,
    });
  };

  return (
    <div>
      <Box mb={3}>
        <Typography variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.title')}
        </Typography>
      </Box>
      <Box mb={1}>
        <Paper>
          <Box p={3}>
            <Box mb={2}>
              <Typography gutterBottom variant="subtitle2">
                {t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.question')}
              </Typography>
            </Box>
            <Select
              fullWidth
              label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.select.label')}
              onChange={handleSelectCustomerOption}
              value={data.existingCustomerOption}
              variant="outlined"
            >
              <MenuItem value="existing">
                {t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.select.options.existing')}
              </MenuItem>
              <MenuItem data-e2e-id="newCustomer" value="new">
                {t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.select.options.new')}
              </MenuItem>
            </Select>
          </Box>
        </Paper>
      </Box>
      {!isNil(data.existingCustomerOption) && (
        <Formik
          initialValues={data.request ?? FORM_INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
        >
          {(formikBag) => (
            <Form
              {...formikBag}
              data={data}
              onGoToConfirmationPage={onGoToConfirmationPage}
              setData={setData}
              t={t}
            />
          )}
        </Formik>
      )}
    </div>
  );
}

function Form(props) {
  const {
    t,
    data,
    setData,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
    onGoToConfirmationPage,
  } = props;

  useEffect(() => {
    setData({
      ...data,
      request: values,
      smsCode: null,
    });
  }, [values]);
  useEffect(() => {
    setFieldValue('existingCustomerOption', data.existingCustomerOption);
  }, [data.existingCustomerOption]);

  const handleInsertTestData = () => {
    setValues({
      name: 'Testkunde',
      email: 'test@daskaloffkempf.com',
      phone: '016094691547',
      licensePlate: 'TE-ST 1111',
      model: 'Testmodell',
      vin: 'WVWZZZ1JZ3W386752',
      message: 'Testnachricht',
      dateOfFirstRegistration: DateTime.local(),
    });
  };
  const handleGoToConfirmationPage = () => {
    onGoToConfirmationPage();
  };

  return (
    <form onSubmit={handleSubmit}>
      {publicRuntimeConfig.isDev && (
        <Box mb={1}>
          <Button onClick={handleInsertTestData}>
            {t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.testData')}
          </Button>
          <Button onClick={handleGoToConfirmationPage}>
            Zur Bestätigung
          </Button>
        </Box>
      )}
      <Grid2 container spacing={3}>
        <Grid2 size={{ md: 6, xs: 12 }}>
          <Paper>
            <Box p={3}>
              <Typography gutterBottom variant="subtitle2">
                {t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.necessaryInformation')}
              </Typography>
              <Box mb={1}>
                <TextInput
                  error={errors.name && touched.name}
                  fullWidth
                  helperText={errors.name && t(`components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.${errors.name}`)}
                  label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.yourName')}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.name}
                />
              </Box>
              <Box mb={1}>
                <TextInput
                  error={errors.phone && touched.phone}
                  fullWidth
                  helperText={t(`components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.${errors.phone ? errors.phone : 'phoneNumberHelperText'}`)}
                  label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.phoneNumber')}
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.phone}
                />
              </Box>
              <Box mb={1}>
                <TextInput
                  error={errors.licensePlate && touched.licensePlate}
                  fullWidth
                  helperText={errors.licensePlate && t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.licensePlateHelperText')}
                  label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.vehicleRegistrationNumber')}
                  name="licensePlate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.licensePlate}
                />
              </Box>
              {data.existingCustomerOption === 'new' && (
                <>
                  <Box mb={1}>
                    <TextInput
                      error={errors.vin && touched.vin}
                      fullWidth
                      helperText={errors.vin && t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.vehicleIdentificationNumberHelperText')}
                      label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.vehicleIdentificationNumber')}
                      name="vin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size="small"
                      value={values.vin}
                    />
                  </Box>
                  <Box mb={1}>
                    <DatePicker
                      cancelLabel="Abbrechen"
                      disableFuture
                      format="dd.MM.yyyy"
                      fullWidth
                      id="dateOfFirstRegistration"
                      label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.firstRegistrationDate')}
                      margin="normal"
                      name="dateOfFirstRegistration"
                      okLabel="OK"
                      onChange={(date) => setFieldValue('dateOfFirstRegistration', date)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                      value={values.dateOfFirstRegistration}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Grid2>
        <Grid2 size={{ md: 6, xs: 12 }}>
          <Paper>
            <Box p={3}>
              <Typography gutterBottom variant="subtitle2">
                {t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.optionalInformation')}
              </Typography>
              <Box mb={1}>
                <TextInput
                  error={errors.email && touched.email}
                  fullWidth
                  helperText={errors.email}
                  label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.emailAddress')}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.email}
                />
              </Box>
              <Box mb={1}>
                <TextInput
                  error={errors.model && touched.model}
                  fullWidth
                  helperText={errors.model}
                  label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.vehicleModel')}
                  name="model"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.model}
                />
              </Box>
              {data.existingCustomerOption === 'existing' && (
                <>
                  <Box mb={1}>
                    <TextInput
                      error={errors.vin && touched.vin}
                      fullWidth
                      helperText={errors.vin && t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.vehicleIdentificationNumberHelperText')}
                      label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.vehicleIdNumber')}
                      name="vin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size="small"
                      value={values.vin}
                    />
                  </Box>
                  <Box mb={1}>
                    <DatePicker
                      cancelLabel="Abbrechen"
                      disableFuture
                      format="dd.MM.yyyy"
                      id="dateOfFirstRegistration"
                      label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.dateOfFirstRegistration')}
                      margin="normal"
                      okLabel="OK"
                      onChange={(date) => setFieldValue('dateOfFirstRegistration', date)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                      value={values.dateOfFirstRegistration}
                    />
                  </Box>
                </>
              )}
              <Box mb={2}>
                <TextInput
                  error={errors.message && touched.message}
                  fullWidth
                  helperText={errors.message}
                  inputProps={{
                    style: { height: 140 },
                  }}
                  label={t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.furtherInformation')}
                  multiline
                  name="message"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.message}
                />
              </Box>
              <Box>
                <Typography
                  dangerouslySetInnerHTML={{ __html: t('components.organisms.WorkShopAppointmentTool.steps.PersonalDetails.form.dataProtection') }}
                />
              </Box>
            </Box>
          </Paper>
        </Grid2>
      </Grid2>
    </form>
  );
}

Form.propTypes = {
  ...formikInjectedPropsTypes,
};

PersonalDetails.validateStep = function (data) {
  return VALIDATION_SCHEMA.isValidSync(data.request);
};

const FORM_INITIAL_VALUES = {
  name: '',
  email: '',
  phone: '',
  licensePlate: '',
  model: '',
  vin: '',
  dateOfFirstRegistration: null,
  message: '',
};

const VALIDATION_SCHEMA = yup.object().shape({
  existingCustomerOption: yup.string(),
  name: yup.string()
    .min(1)
    .max(40, 'nameMaxHelperText')
    .required('nameHelperText'),
  phone: yup.string()
    .min(5, 'phoneNumberMinHelperText')
    .max(30, 'phoneNumberMaxHelperText')
    .required('phoneNumberYupHelperText'),
  licensePlate: yup.string()
    .min(3)
    .max(10)
    .required('licensePlateHelperText'),
  vin: yup.string()
    .when('existingCustomerOption', {
      is: 'new',
      then: yup
        .string()
        .required('vehicleIdentificationNumberHelperText'),
    }),
  dateOfFirstRegistration: yup.date()
    .nullable()
    .default(null)
    .when('existingCustomerOption', {
      is: 'new',
      then: yup
        .date()
        .required('dateOfFirstRegistrationHelperText'),
    }),
});
