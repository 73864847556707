/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';
import {
  Box,
  Grid2,
  Paper,
  useTheme,
} from '@mui/material';
import {
  ButtonElement,
  MediaElement,
  TypographyElement,
  useContentElement,
} from '@plugins/next-cms-core';
import Container from '@components/atoms/Container';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { useState } from 'react';
import StaffMember from '@components/molecules/StaffMember';
import Slider from '@components/atoms/Slider';

export default function TextEmployeeBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    TextEmployeeBlock.dataSchema,
  );

  const variant = elementData.textEmployeeBlockVariant ?? 'Plain';
  const order = elementData.textEmployeeBlockOrder ?? 'TextMedia';

  const items = [];
  const imageElement = {
    sx: { zIndex: 0 },
    element: <EmployeeBlock data={elementData} variant={variant} />,
  };
  const textElement = {
    sx: { zIndex: 1 },
    element: <TextBlock data={elementData} variant={variant} />,
  };

  switch (order) {
    case 'MediaText':
      items.push(imageElement);
      items.push(textElement);
      break;
    default:
      items.push(textElement);
      items.push(imageElement);
      break;
  }

  const columnSpan = variant === 'Card' ? 6 : 6;
  const columnSpacing = variant === 'Card' ? 0 : 10;

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        '& $mediaContainer': {
          transformOrigin: 'right center',
        },
        '& $mediaLabel': {},
        ...(order === 'MediaText' && ({
          '& $mediaContainer': {
            transformOrigin: 'left center',
          },
          '& $mediaLabel': {
            left: theme.shape.borderRadius,
          },
        })),
        ...(variant === 'Card' && ({
          marginTop: theme.spacing(10),
          marginBottom: theme.spacing(10),
          '& $mediaContainer': {
            transform: 'scale(1.2)',
          },
          '& $mediaLabel': {
            maxWidth: '70%',
          },
        })),
      }}
    >
      <Container>
        <Box my={10}>
          <Grid2
            alignItems="center"
            container
            spacing={columnSpacing}
          >
            {items.map((item, index) => (
              <Grid2
                key={index}
                size={{ xs: 12, md: columnSpan }}
                sx={item.sx}
              >
                {item.element}
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
}

function EmployeeBlock(props) {
  const { data } = props;
  const {
    employees,
  } = data;
  const [swiper, setSwiper] = useState();

  if (employees.length === 0) {
    return null;
  }

  return (
    <Slider
      autoplay={{
        delay: 5000,
      }}
      isButtonsVisible={false}
      items={employees.data ?? []}
      setSwiper={setSwiper}
      SlideComponent={StaffMember}
      slideComponentProps={{
        variant: 'horizontal',
        keyItemName: 'employee',
      }}
      sliderStyles={{
        padding: 7,
        margin: -7,
        paddingTop: 20,
        height: '100%',
      }}
      swiper={swiper}
      swiperSlideStyles={{
        display: 'flex',
      }}
    />
  );
}

function TextBlock(props) {
  const { variant, data } = props;
  const { buttons } = data;

  const title = omitBy(data.textEmployeeBlockTitle, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const subtitle = omitBy(data.textEmployeeBlockSubtitle, isNull);
  defaults(subtitle, {
    semanticVariant: 'div',
    displayVariant: 'body2',
  });

  const text = omitBy(data.textEmployeeBlockText, isNull);
  defaults(text, {
    semanticVariant: 'div',
    displayVariant: 'body1',
  });

  const content = (
    <>
      {subtitle?.value && (
        <Box mb={2}>
          <TypographyElement
            color="primary"
            data={subtitle}
            sx={{
              '& p': {
                margin: 0,
              },
            }}
          />
        </Box>
      )}
      {title?.value && (
        <Box mb={3}>
          <TypographyElement
            data={title}
            sx={{
              '& p': {
                marginTop: 0,
              },
            }}
          />
        </Box>
      )}
      {text?.value && (
        <Box>
          <TypographyElement data={text} />
          {buttons && (
            <Box mt={5}>
              <Grid2 container spacing={2}>
                {buttons.map((button) => (
                  <Grid2 key={button.id}>
                    <ButtonElement
                      color="primary"
                      component="a"
                      data={button}
                      variant="contained"
                    />
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          )}
        </Box>
      )}
    </>
  );

  if (variant === 'Card') {
    return (
      <Paper elevation={3}>
        <Box p={5}>
          {content}
        </Box>
      </Paper>
    );
  }

  return content;
}

const PROPTYPE_VARIANT = PropTypes.oneOf(['Plain', 'Card']);

TextEmployeeBlock.typeName = 'ComponentContentTextEmployee'; // Strapi element type
TextEmployeeBlock.propTypes = {
  data: PropTypes.shape({
    textEmployeeBlockVariant: PROPTYPE_VARIANT,
    textEmployeeBlockOrder: PropTypes.oneOf(['TextMedia', 'MediaText']),
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes)),
    employees: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.propTypes,
      name: PropTypes.string.propTypes,
      position: PropTypes.string.propTypes,
      email: PropTypes.string.propTypes,
      phone: PropTypes.string.propTypes,
      media: MediaElement.propTypes,
    })),
  }).isRequired,
};
TextEmployeeBlock.dataSchema = yup.object().shape({
  textEmployeeBlockVariant: yup.string().nullable().oneOf([null, 'Plain', 'Card']),
  textEmployeeBlockOrder: yup.string().nullable().oneOf([null, 'TextMedia', 'MediaText']),
  employees: yup.object().shape({
    data: yup.array().of(yup.object().shape({
      id: yup.number().nullable(),
      name: yup.string().nullable(),
      position: yup.string().nullable(),
      email: yup.string().nullable(),
      phone: yup.string().nullable(),
      media: yup.array(MediaElement.dataSchema).nullable(),
    })),
  }),
  textEmployeeBlockTitle: TypographyElement.dataSchema,
  textEmployeeBlockSubtitle: TypographyElement.dataSchema,
  textEmployeeBlockText: TypographyElement.dataSchema,
  buttons: yup.array(ButtonElement.dataSchema).nullable(),
});
TextEmployeeBlock.graphQlSchema = `
... on ${TextEmployeeBlock.typeName} {
  id
  textEmployeeBlockVariant: variant
  textEmployeeBlockOrder: order
  textEmployeeBlockTitle: title {
    ${TypographyElement.graphQlSchema}
  }
  textEmployeeBlockSubtitle: subtitle {
    ${TypographyElement.graphQlSchema}
  }
  textEmployeeBlockText: text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
  employees {
    data {
      id
      attributes {
        name
        position
        phone
        phoneWhatsapp
        email
        image {
          ${MediaElement.graphQlSchema}
        }
        primary_branch {
          data {
            attributes {
              name
              pageUrl
            }
          }
        }
        languages(sort: "name") {
          data {
            id
            attributes {
              name
              emoji
            }
          }
        }
      }
    }
  }
}
`;

TextBlock.propTypes = {
  data: PropTypes.shape({
    variant: PROPTYPE_VARIANT,
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
  }).isRequired,
};

EmployeeBlock.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.oneOf(['image', 'video']),
    }),
  }).isRequired,
};
