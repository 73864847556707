/* eslint-disable max-len */
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Virtual } from 'swiper';
import React, { useState } from 'react';
import { Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import Hidden from '@components/atoms/Hidden';
import { useRouter } from 'next/router';

SwiperCore.use([Virtual]);
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

export default function Slider(props) {
  const theme = useTheme();
  const {
    autoplay,
    autoHeight,
    swiper,
    setSwiper,
    count = 1,
    items,
    isButtonsVisible = true,
    SlideComponent,
    slideComponentProps = {},
    sliderStyles = {},
    pageQueryParam = {},
    swiperSlideStyles,
    pagination,
    allowTouchMove = true,
    handleSlideChange,
  } = props;
  const router = useRouter();
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  const updatePageQueryParam = (index) => {
    if (pageQueryParam) {
      const queryParams = new URLSearchParams(window.location.search);
      const hasQueryParam = queryParams.has(pageQueryParam);
      queryParams.set(pageQueryParam, index);
      const updatedPathname = `${router.asPath.split('?')[0]}?${queryParams.toString()}`;
      router[hasQueryParam ? 'replace' : 'push'](updatedPathname, undefined, { shallow: true });
      setCanGoPrevious(index > 0);
      setCanGoNext(index < swiper.snapGrid.length - 1);
    }
  };

  let handleSlideChange2 = () => {
    updatePageQueryParam(swiper.activeIndex);
  };

  if (handleSlideChange) {
    handleSlideChange2 = handleSlideChange;
  }

  const handleGoToPreviousPage = () => {
    updatePageQueryParam(swiper.activeIndex - 1);
  };
  const handleGoToNextPage = () => {
    updatePageQueryParam(swiper.activeIndex + 1);
  };

  const onSlideChangeHandler = (newSwiper) => {
    newSwiper.pagination.bullets.forEach((item, index) => {
      if (index < newSwiper.activeIndex) {
        // eslint-disable-next-line no-param-reassign
        item.className = 'swiper-pagination-bullet swiper-pagination-bullet-active';
      }
    });
  };

  if (!items.length) {
    return null;
  }

  return (
    <>
      <Hidden dir="down" size="md">
        <Button
          aria-label="previous"
          disabled={!canGoPrevious}
          onClick={handleGoToPreviousPage}
          size="small"
          sx={{
            position: 'absolute',
            display: isButtonsVisible ? 'block' : 'none',
            width: 50,
            minWidth: 0,
            top: 7,
            bottom: 30,
            left: `calc(-50px - ${theme.spacing(1)})`,
            color: theme.palette.text.primary,
          }}
        >
          <FiArrowLeft
            style={{
              width: 20,
              height: 20,
            }}
          />
        </Button>
      </Hidden>
      <Swiper
        allowTouchMove={allowTouchMove}
        autoHeight={autoHeight}
        autoplay={autoplay}
        modules={[Pagination]}
        onActiveIndexChange={handleSlideChange2}
        onSlideChange={onSlideChangeHandler}
        onSwiper={(newSwiper) => setSwiper(newSwiper)}
        pagination={{
          clickable: true,
          ...pagination,
        }}
        slidesPerView={count}
        spaceBetween={20}
        style={sliderStyles}
        virtual={{
          addSlidesAfter: 3,
          addSlidesBefore: 3,
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={item.id}
            style={swiperSlideStyles}
            virtualIndex={index}
          >
            <SlideComponent
              item={item}
              {...(slideComponentProps.keyItemName ? { [slideComponentProps.keyItemName]: item } : {})}
              {...slideComponentProps}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Hidden dir="down" size="md">
        <Button
          aria-label="next"
          disabled={!canGoNext}
          onClick={handleGoToNextPage}
          size="small"
          sx={{
            position: 'absolute',
            display: isButtonsVisible ? 'block' : 'none',
            width: 50,
            minWidth: 0,
            top: 7,
            bottom: 30,
            right: -50,
            color: theme.palette.text.primary,
          }}
        >
          <FiArrowRight
            style={{
              width: 20,
              height: 20,
            }}
          />
        </Button>
      </Hidden>
      <style global jsx>
        {`

          .swiper-container {
            position: relative;
            padding-top: 18px;
          }

          .swiper-wrapper {
            align-items: stretch;
          }

          .swiper-pagination-bullet-active {
            background: ${theme.palette.primary.main};
          }

          .swiper-pagination {
            position: absolute;
            top: 0;
            display: flex;
            height: 8px;
            z-index: 15;
          }

          .swiper-pagination .swiper-pagination-bullet {
            border-radius: 3px;
            width: 100%;
            height: 6px;
            background: ${theme.palette.primary.main};
          }

          .swipeSlideItem {
            height: auto;
            display: flex;
          }

        `}
      </style>
    </>
  );
}

Slider.propTypes = {
  count: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  swiper: PropTypes.object,
  setSwiper: PropTypes.func.isRequired,
  SlideComponent: PropTypes.elementType.isRequired,
  slideComponentProps: PropTypes.object,
  sliderStyles: PropTypes.object,
  pageQueryParam: PropTypes.string,
  isButtonsVisible: PropTypes.bool,
  pagination: PropTypes.object,
  allowTouchMove: PropTypes.bool,
};
