import * as yup from 'yup';
import {
  Box,
  CardActionArea,
  Grid2,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import {
  MediaElement,
  RibbonElement,
  TypographyElement,
  useContentElement,
} from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';
import Link from '../atoms/Link';

export default function SimilarPagesBlock(props) {
  const {
    data,
    context,
  } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    SimilarPagesBlock.dataSchema,
  );

  const { similarPagesBlockTags: tags } = elementData;

  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const pages = tags.data.flatMap((tag) => tag.attributes.similarPagesBlockPages.data
    .filter((item) => item.id !== context.page.id));

  return (
    <Box
      sx={{
        '& *': {
          textDecoration: 'none !important',
        },
      }}
    >
      <Container>
        <Box
          mb={5}
          sx={{
            paddingTop: theme.spacing(10),
          }}
        >
          <TypographyElement
            data={title}
          />
        </Box>
        {pages.map((item) => (
          <Box key={item.id} mb={5}>
            <PagePreviewItem item={item} theme={theme} />
          </Box>
        ))}
      </Container>
    </Box>
  );
}

function PagePreviewItem({ item, theme }) {
  const { url } = item.attributes;

  return (
    <Paper
      elevation={3}
      sx={{
        '& *': {
          textDecoration: 'none !important',
        },
      }}
    >
      <Link href={url}>
        <CardActionArea>
          <Grid2 alignItems="stretch" container>
            {item.attributes.blogPreviewMedia && (
              <Grid2
                size={{ sm: 3, xs: 12 }}
                sx={{
                  position: 'relative',
                  [theme.breakpoints.down('xs')]: {
                    marginBottom: theme.spacing(2),
                  },
                  [theme.breakpoints.up('sm')]: {
                    marginRight: theme.spacing(2),
                  },
                }}
              >
                <MediaElement
                  data={item.attributes.blogPreviewMedia}
                  isFluid
                  style={{
                    flex: 1,
                    [theme.breakpoints.down('xs')]: {
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                    [theme.breakpoints.up('sm')]: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    },
                  }}
                />
                {item.attributes.blogPreviewMediaRibbon && (
                  <RibbonElement data={item.attributes.blogPreviewMediaRibbon} />
                )}
              </Grid2>
            )}
            <Grid2
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(3),
              }}
            >
              <Typography component="h4" variant="h5">
                {item.attributes.name}
              </Typography>
              <Box my={3}>
                {item.attributes.blogPreviewText && (
                  <TypographyElement data={item.attributes.blogPreviewText} />
                )}
              </Box>
            </Grid2>
          </Grid2>
        </CardActionArea>
      </Link>
    </Paper>
  );
}

SimilarPagesBlock.typeName = 'ComponentContentSimilarPages'; // Strapi element type
SimilarPagesBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
  }).isRequired,
  context: PropTypes.object,
};
SimilarPagesBlock.dataSchema = yup.object()
  .shape({
    title: TypographyElement.dataSchema,
  });
SimilarPagesBlock.graphQlSchema = `
... on ${SimilarPagesBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  similarPagesBlockTags: tags {
    data {
      id
      attributes {
        name
        similarPagesBlockPages: pages(sort: "name:asc", filters: { locale: { eq: $localeStr } }) {
          data {
            id
            attributes {
              name
              url
              slug
              pageType
              publishedAt
              publishDate
              blogPreviewText {
                ${TypographyElement.graphQlSchema}
              }
              blogPreviewMedia {
                ${MediaElement.graphQlSchema}
              }
              blogPreviewMediaRibbon {
                ${RibbonElement.graphQlSchema}
              }
            }
          }
        }
      }
    }
  }
}
`;
