import React, { forwardRef } from 'react';
import NativeButton from '@mui/material/Button';

function Button(props, ref) {
  return (
    <NativeButton ref={ref} {...props} />
  );
}

export default forwardRef(Button);

Button.propTypes = {
  ...NativeButton.propTypes,
};
