/* eslint-disable react/no-danger */
import ProvenExpertContext from '../../context/ProvenExpertContext';

export default function ProvenExpertGoogleStars() {
  return (
    <ProvenExpertContext.Consumer>
      {(value) => (
        <div dangerouslySetInnerHTML={{
          __html: value?.aggregateRating,
        }}
        />
      )}
    </ProvenExpertContext.Consumer>
  );
}
