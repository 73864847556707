function filterFields(groups, filterFn = () => true) {
  return groups
    .map((group) => ({
      ...group,
      fields: group.fields
        .filter((field) => field.event_form_field?.data?.id
          && field.event_form_field.data.attributes.type.length > 0
          && filterFn(field)),
    }))
    .filter((group) => group.fields.length > 0);
}

module.exports = filterFields;
