import React, { useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import { FiCheckCircle } from 'react-icons/fi';
import {
  Box,
  Button,
  Paper,
  Typography,
  Alert,
  useTheme,
} from '@mui/material';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import Container from '@components/atoms/Container';
import { useTranslation } from 'react-i18next';
import Form from '@components/molecules/form/Form';
import { FORM_FIELDS } from '@components/molecules/form/fieldMapping';
import { useRouter } from 'next/router';

export default function FormBlock(props) {
  const { data } = props;
  const [isFormVisible, setFormVisible] = useState(true);
  const theme = useTheme();
  const router = useRouter();
  const { t } = useTranslation();
  const { elementData } = useContentElement(
    data,
    FormBlock.dataSchema,
  );

  let title = null;
  const isNewFormAllowed = elementData.form.postSubmitAction?.[0]?.isNewFormAllowed;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      }}
    >
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        {!isFormVisible && isNewFormAllowed && (
          <Box display="flex" justifyContent="center">
            <Button
              color="primary"
              onClick={() => setFormVisible(true)}
              size="small"
              variant="outlined"
            >
              {t('components.contentTypes.FormBlock.newRequest')}
            </Button>
          </Box>
        )}
        {!isFormVisible && !isNewFormAllowed && (
          <Paper
            sx={{
              paddingTop: theme.spacing(5),
              paddingBottom: theme.spacing(5),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            variant="outlined"
          >
            <Box alignItems="center" display="flex" mb={2}>
              <Box mr={1}>
                <FiCheckCircle style={{
                  display: 'block',
                  width: 22,
                  height: 22,
                }}
                />
              </Box>
              <Typography variant="h6">
                {t('components.contentTypes.FormBlock.thankYou')}
              </Typography>
            </Box>
            <Typography variant="subtitle1">
              {t('components.contentTypes.FormBlock.requestReceived')}
            </Typography>
            <Box mt={5}>
              <Button
                color="primary"
                onClick={() => setFormVisible(!isFormVisible)}
                size="small"
                variant="outlined"
              >
                {t('components.contentTypes.FormBlock.backToForm')}
              </Button>
            </Box>
          </Paper>
        )}
        {isFormVisible && elementData.form?.data && (
          <Form
            form={elementData.form.data.attributes}
            queries={router.query}
            setFormVisible={setFormVisible}
          />
        )}
        {!elementData.form?.data && (
          <Alert severity="error">
            Formular konnte nicht geladen werden
          </Alert>
        )}
      </Container>
    </Box>
  );
}

FormBlock.typeName = 'ComponentContentForm'; // Strapi element type

FormBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    form: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};

FormBlock.dataSchema = yup.object()
  .shape({
    title: TypographyElement.dataSchema.nullable(),
    form: yup.object()
      .shape({
        data: yup.object()
          .shape({
            id: yup.number()
              .required(),
            attributes: yup.object()
              .shape({
                name: yup.string()
                  .nullable(),
              }),
          })
          .nullable(),
      })
      .nullable(),
  });

FormBlock.graphQlSchema = `
... on ${FormBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  form {
    data {
      id
      attributes {
        name
        sendButtonLabel
        recipientEmail
        recipientEmailTemplateId
        receiptEmailTemplateId
        sendReceiptEmailToIssuer
        items(pagination: {limit: 100}) {
          id
          title
          isCollapsed
          isConditional
          visibility
          matches
          fields(pagination: {limit: 100}) {
            id
            name
            label
            helperText
            isRequired
            isHidden
            isDisabled
            isReadOnly
            isConditional
            visibility
            matches
            rules {
              field
              compare
              value
            }
            layout {
              width
            }
            ${Object.keys(FORM_FIELDS).map((type) => `
              ${FORM_FIELDS[type].graphQlSchema}
            `)}
          }
          layout {
            width
          }
          rules {
            field
            compare
            value
          }
        }
        cc {
          email
        }
        bcc {
          email
        }
        postSubmitAction {
          ... on ComponentFormFormActionShowSuccessMessage {
            isNewFormAllowed
          }
          ... on ComponentFormFormActionRedirectToUrl {
            url
          }
        }
      }
    }
  }
}
`;
