import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid2,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { helper } from '@lib/form';
import { useRouter } from 'next/router';
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import FormField from './FormField';
import { layoutWidth } from '../../../constants';

export default function FormFieldGroup(props) {
  const {
    title,
    fields,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    formDisplayState,
    isCollapsed,
  } = props;
  const theme = useTheme();
  const router = useRouter();
  const [isGroupCollapsed, setIsGroupCollapsed] = useState(isCollapsed);

  useEffect(() => {
    if (Object.keys(router.query).length > 1) {
      setIsGroupCollapsed(false);
    }
  }, [router.query]);

  const handleToggle = useCallback(() => {
    setIsGroupCollapsed(!isGroupCollapsed);
  }, [isGroupCollapsed]);

  const visibleFields = fields.filter((field) => formDisplayState[`field_${field.id}`].isVisible);

  if (visibleFields.length === 0) {
    return null;
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Title
          isCollapsed={isGroupCollapsed}
          onToggle={handleToggle}
          value={title}
        />
        <Collapse in={!isGroupCollapsed}>
          <Grid2
            container
            spacing={3}
            sx={{
              alignItems: 'flex-end',
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }}
          >
            {visibleFields.filter((field) => !field.isHidden).map((field) => (
              <Grid2
                key={field.id}
                size={{
                  md: layoutWidth[field.layout?.width] ?? 12,
                  xs: 12,
                }}
              >
                <FormField
                  {...field}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id={`field_${field.id}`}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  type={helper.getFieldType(field)}
                  values={values}
                />
              </Grid2>
            ))}
          </Grid2>
        </Collapse>
      </CardContent>
    </Card>
  );
}

FormFieldGroup.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  formDisplayState: PropTypes.object,
  isCollapsed: PropTypes.bool,
};

function TitleComponent(props) {
  const {
    isCollapsed,
    value,
    onToggle,
  } = props;
  const theme = useTheme();

  return (
    <Tooltip
      placement="top"
      title={isCollapsed ? 'Ausklappen' : 'Einklappen'}
    >
      <Box
        alignItems="center"
        onClick={onToggle}
        sx={{
          display: 'flex',
          cursor: 'pointer',
          marginBottom: theme.spacing(1),
        }}
      >
        <IconButton
          size="small"
          sx={{
            marginRight: theme.spacing(1),
          }}
        >
          {isCollapsed ? <FiArrowDown /> : <FiArrowUp />}
        </IconButton>
        <Typography
          component="div"
          variant="subtitle1"
        >
          {value}
        </Typography>
      </Box>
    </Tooltip>
  );
}

TitleComponent.propTypes = {
  isCollapsed: PropTypes.bool,
  value: PropTypes.string,
  onToggle: PropTypes.func,
};

const Title = memo(TitleComponent);
