const yup = require('yup');

function deserializeValue(state) {
  if (Array.isArray(state.value)) {
    if (state.value.length > 0) return state.value[0];
    return '';
  }

  return state.value;
}

module.exports = {
  getDefaultValue: () => '',
  serializeValue: (value) => ({ value }),
  deserializeValue,
  getDisplayValue: (...args) => deserializeValue(...args),
  validationSchema: ({ isMultiline }) => yup
    .string()
    .nullable()
    .max(
      isMultiline ? 4000 : 255,
      isMultiline ? 'Maximal 4000 Zeichen möglich.' : 'Maximal 255 Zeichen möglich.',
    ),
};
