import { Box, Button, Grid2 } from '@mui/material';
import { memo } from 'react';

function FormActions(props) {
  const {
    form,
    dirty,
    isValid,
    isSubmitting,
  } = props;

  return (
    <Box mt={3}>
      <Grid2 container justifyContent="center">
        <Grid2
          size={{
            xs: 12, sm: 6, md: 4, lg: 3,
          }}
        >
          <Button
            color="primary"
            disabled={!dirty || !isValid || isSubmitting}
            fullWidth
            type="submit"
            variant="contained"
          >
            {form.sendButtonLabel || 'Senden'}
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default memo(FormActions);
