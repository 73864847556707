/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';
import {
  Box,
  Grid2,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material';
import {
  MediaElement,
  TypographyElement,
  ButtonElement,
  useContentElement,
} from '@plugins/next-cms-core';
import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import PropTypes from 'prop-types';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import defaults from 'lodash/defaults';
import Container from '../atoms/Container';
import ReadMoreContainer from '../atoms/ReadMoreContainer';

export default function AccordionSliderBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const [expandedPanel, setExpandedPanel] = useState(0);
  const { elementData } = useContentElement(
    data,
    AccordionSliderBlock.dataSchema,
  );

  const { accordionSliderSlides } = elementData;
  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  if (!accordionSliderSlides || accordionSliderSlides.length === 0) {
    return null;
  }

  const currentSlide = accordionSliderSlides[expandedPanel];

  const handleExpandPanel = (index) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? index : 0);
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      }}
    >
      {title && (
        <TypographyElement data={title} mb={5} />
      )}
      <Grid2
        container
        spacing={3}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Grid2 size={{
          md: 7,
          xs: 12,
        }}
        >
          <MediaElement data={currentSlide.media} />
        </Grid2>
        <Grid2 size={{
          md: 5,
          xs: 12,
        }}
        >
          {accordionSliderSlides.map((item, itemIndex) => (
            <Accordion
              key={itemIndex}
              expanded={expandedPanel === itemIndex}
              onChange={handleExpandPanel(itemIndex)}
            >
              <AccordionSummary expandIcon={<FiChevronDown />}>
                <Typography component="h3" sx={{ marginRight: 'auto' }} variant="subtitle2">
                  {item.category}
                  {' '}
                </Typography>
                {item.suffix && expandedPanel !== itemIndex && (
                  <Typography component="h3" variant="subtitle2">
                    {item.suffix}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {item.title && (
                    <Typography
                      sx={{
                        mb: 3,
                        '& > p': {
                          margin: 0,
                        },
                      }}
                      variant="h3"
                    >
                      {item.title}
                    </Typography>
                  )}
                  <ReadMoreContainer
                    data={item}
                    expandedPanel={expandedPanel}
                    itemIndex={itemIndex}
                    suffix={item.suffix}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid2>
      </Grid2>
    </Container>
  );
}

AccordionSliderBlock.typeName = 'ComponentContentAccordion'; // Strapi element type
AccordionSliderBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    accordionSliderSlides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      category: PropTypes.string.isRequired,
      media: MediaElement.propTypes,
      text: TypographyElement.propTypes,
    })),
  }).isRequired,
};

AccordionSliderBlock.dataSchema = yup.object()
  .shape({
    title: TypographyElement.dataSchema.nullable(),
    accordionSliderSlides: yup.array()
      .of(yup.object()
        .shape({
          id: yup.number()
            .required(),
          title: yup.string()
            .nullable(),
          category: yup.string()
            .required(),
          media: MediaElement.dataSchema.required(),
          text: TypographyElement.dataSchema,
        })),
  });

AccordionSliderBlock.graphQlSchema = `
... on ${AccordionSliderBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  accordionSliderSlides: slides {
    id
    category
    suffix
    media {
      ${MediaElement.graphQlSchema}
    }
    title
    text {
      ${TypographyElement.graphQlSchema}
    }
    buttons {
      ${ButtonElement.graphQlSchema}
    }
  }
}
`;
