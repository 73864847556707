import 'react-calendar/dist/Calendar.css';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid2,
  IconButton,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  useTheme,
} from '@mui/material';
import { FiArrowLeft, FiArrowRight, FiMapPin } from 'react-icons/fi';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';
import {
  useCallback, useEffect, useState, Fragment,
} from 'react';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';

export default function Appointment(props) {
  const {
    t, data, setData, onGoNext,
  } = props;
  const [selectedDate, setSelectedDate] = useState();
  const { i18n } = useTranslation();

  const {
    error2,
    data: branchData,
  } = useQuery(FETCH_BRANCHES, {
    variables: {
      locale: i18n.language,
    },
  });
  const [
    fetchAvailableDates,
    {
      data: datesData,
      error,
      loading,
    },
  ] = useLazyQuery(FETCH_AVAILABLE_DATES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchAvailableDates({
      fetchPolicy: 'network-only',
      variables: {
        calendar: data.calendarId,
        services: data.selectedServices,
      },
    });
  }, [
    fetchAvailableDates,
    data.calendarId,
    data.selectedServices,
  ]);

  if (error || error2) {
    throw error;
  }

  const branches = branchData?.branches.data ?? [];
  const items = datesData?.workshopCapacities.dates.map((item) => ({
    ...item,
    date: DateTime.fromISO(item.date)
      .toJSDate(),
  })) ?? [];

  const handleReset = () => {
    setSelectedDate(null);
  };
  const handleSelectDate = (date) => {
    setSelectedDate(date);
    setData({
      ...data,
      selectedDate: date,
    });
  };
  const handleSelectTime = (time) => () => {
    setData({
      ...data,
      selectedTime: time,
    });
    onGoNext();
  };

  return (
    <div>
      <Box mb={3}>
        <Typography variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.Appointment.question')}
        </Typography>
      </Box>
      {loading ? (
        <DateCalendarLoading />
      ) : (
        <>
          {items.length === 0 && (
            <Typography>
              {t('components.organisms.WorkShopAppointmentTool.steps.Appointment.noFreeTimeSlots')}
            </Typography>
          )}
          {!selectedDate ? (
            <>
              <DateCalendar
                data={data}
                items={items}
                onSelect={handleSelectDate}
              />
              <Appointments
                branches={branches.filter((branch) => branch.id !== data.branch.id)}
                data={data}
                earliestDate={items[0]}
                fetchAvailableDates={fetchAvailableDates}
                setData={setData}
                t={t}
              />
            </>
          ) : (
            <TimeSlots
              aw={datesData?.workshopCapacities.aw}
              data={data}
              date={selectedDate}
              onReset={handleReset}
              onSelect={handleSelectTime}
            />
          )}
        </>
      )}
    </div>
  );
}

function DateCalendarLoading() {
  const headerHeight = 84;
  const slotHeight = 42;
  const theme = useTheme();

  return (
    <Paper
      sx={{
        padding: theme.spacing(1),
        position: 'relative',
      }}
    >
      <Grid2 container spacing={1}>
        <Grid2 size={{ xs: 12 }}>
          <Box
            sx={{
              width: '100%',
              height: headerHeight,
            }}
          />
        </Grid2>
        {[
          ...Array(5)
            .keys(),
        ].map((i) => (
          <Fragment key={i}>
            <Grid2 size={{ xs: 2 }} />
            <Grid2 size={{ xs: 2 }}>
              <Skeleton height={slotHeight} variant="rect" />
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Skeleton height={slotHeight} variant="rect" />
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Skeleton height={slotHeight} variant="rect" />
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Skeleton height={slotHeight} variant="rect" />
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Skeleton height={slotHeight} variant="rect" />
            </Grid2>
          </Fragment>
        ))}
      </Grid2>
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="subtitle2">
            Termine werden abgerufen...
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

function DateCalendar({
  items,
  data,
  onSelect,
}) {
  const theme = useTheme();
  const minDate = items.length > 0 ? items[0].date : DateTime.now()
    .toJSDate();
  const maxDate = items.length > 0 ? items[items.length - 1].date : DateTime.now()
    .toJSDate();

  return (
    <Paper
      sx={{
        padding: theme.spacing(1),
        '& .react-calendar': {
          width: 'auto',
          border: 'none',
        },
        '& .react-calendar__tile': {
          height: 50,
          borderStyle: 'solid',
          borderWidth: theme.spacing(0.5),
          borderColor: 'white',
          borderRadius: `${theme.shape.borderRadius}px`,
        },
        '& .react-calendar__tile.react-calendar__month-view__days__day:not(:disabled)': {
          backgroundColor: '#4CAF50',
          transition: theme.transitions.create(['background-color']),
          color: 'white',
          '&:hover, &:focus': {
            backgroundColor: '#81C784',
          },
        },
        '& .react-calendar__navigation button[disabled]': {
          background: 'none',
        },
      }}
    >
      <Calendar
        locale="de-DE"
        maxDate={maxDate}
        maxDetail="month"
        minDate={minDate}
        minDetail="month"
        navigationLabel={NavigationLabel}
        next2Label={null}
        nextLabel={<FiArrowRight />}
        onChange={onSelect}
        prev2Label={null}
        prevLabel={<FiArrowLeft />}
        showWeekNumbers
        tileDisabled={({ date }) => !items.find((d) => d.date.getTime() === date.getTime()
          && (!data.isAnyServiceDisallowedOnSaturday || date.getDay() !== 6))}
        // eslint-disable-next-line react/no-unstable-nested-components
        value={data.selectedDate ?? minDate}
      />
    </Paper>
  );
}

function NavigationLabel({ label }) {
  return (
    <Typography variant="subtitle2">
      {label}
    </Typography>
  );
}

function Appointments(props) {
  const {
    t,
    branches,
    data,
    fetchAvailableDates,
    setData,
    earliestDate,
  } = props;
  const theme = useTheme();
  const { i18n } = useTranslation();
  const [fetchCalendars] = useLazyQuery(FETCH_CALENDARS);
  const [fetchDates] = useLazyQuery(FETCH_AVAILABLE_DATES, {
    fetchPolicy: 'network-only',
  });
  const [branchData, setBranchData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchAppointments = useCallback(async () => {
    try {
      setLoading(true);

      const result = await Promise.all(branches.map((branch) => fetchCalendars({
        fetchPolicy: 'network-only',
        variables: {
          branchId: branch.id,
          name: data.calendar.attributes.name,
          locale: i18n.language,
        },
      })
        .then((workshopCalendarsResult) => {
          const { id } = workshopCalendarsResult.data.workshopCalendars.data[0];

          return fetchDates({
            fetchPolicy: 'network-only',
            variables: {
              calendar: id,
              services: data.selectedServices,
            },
          })
            .then((workshopCapacitiesResult) => ({
              id: branch.id,
              ...branch.attributes,
              calendarId: id,
              appointments: workshopCapacitiesResult.data.workshopCapacities.dates,
            }));
        })));

      const branchesData = result.map((branch) => {
        const appointments = branch.appointments
          .filter((appointment) => DateTime.fromISO(appointment.date)
            .toMillis() <= DateTime.fromJSDate(earliestDate.date)
            .plus({ day: 1 })
            .toMillis());

        return {
          ...branch,
          appointments,
        };
      })
        .filter((branch) => branch.appointments.length > 0);

      setBranchData(orderBy([...branchesData], ['appointments[0].date']));
    } catch {
      setBranchData([]);
    } finally {
      setLoading(false);
    }
  }, [
    branches,
    data.calendar.attributes.name,
    data.selectedServices,
    earliestDate?.date,
    fetchCalendars,
    fetchDates,
    i18n.language,
  ]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments, branches]);

  if (branchData.length === 0) {
    return null;
  }

  return (
    <Box mt={5}>
      <Box alignItems="center" display="flex" mb={3}>
        <Typography variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.Appointment.otherLocations')}
        </Typography>
      </Box>
      <Paper
        sx={{
          padding: theme.spacing(1),
        }}
      >
        <List component="div" dense disablePadding>
          {isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : branchData.map((branch) => (
            <OtherBranchFirstAppointment
              key={branch.id}
              branch={branch}
              data={data}
              fetchAvailableDates={fetchAvailableDates}
              setData={setData}
              t={t}
            />
          ))}
        </List>
      </Paper>
    </Box>
  );
}

function TimeSlotsLoading({
  backButton,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: theme.spacing(1),
        position: 'relative',
      }}
    >
      <Grid2 alignItems="center" container spacing={3}>
        {backButton}
        {[
          ...Array(17)
            .keys(),
        ].map((i) => (
          <Grid2 key={i} size={{ md: 2, xs: 3 }}>
            <Skeleton sx={{ height: 54 }} variant="rect" />
          </Grid2>
        ))}
      </Grid2>
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="subtitle2">
            Uhrzeiten werden abgerufen...
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function TimeSlots(props) {
  const {
    aw,
    date,
    data,
    onSelect,
    onReset,
  } = props;
  const [fetchTimeslots, {
    error,
    data: timeSlotsData,
    loading,
  }] = useLazyQuery(FETCH_TIMESLOTS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data.calendarId && date) {
      fetchTimeslots({
        variables: {
          calendar: data.calendarId,
          date: DateTime.fromJSDate(date)
            .toFormat('yyyy-MM-dd'),
          aw,
        },
      });
    }
  }, [fetchTimeslots, data.calendarId, date]);

  if (error) {
    throw error;
  }

  const backButton = (
    <Grid2 size={{ md: 2, xs: 3 }}>
      <Box textAlign="center">
        <IconButton onClick={onReset}>
          <FiArrowLeft />
        </IconButton>
      </Box>
    </Grid2>
  );

  if (loading) {
    return (
      <TimeSlotsLoading backButton={backButton} />
    );
  }

  const workshopTimeSlots = timeSlotsData?.workshopTimeSlots ?? [];

  return (
    <Grid2 alignItems="center" container spacing={3}>
      {backButton}
      {workshopTimeSlots.map((time) => (
        <Grid2 key={time} size={{ md: 2, xs: 3 }}>
          <Card>
            <CardActionArea data-e2e-id="timeSlot" onClick={onSelect(time)}>
              <CardContent>
                <Typography align="center" variant="subtitle2">
                  {time}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );
}

function OtherBranchFirstAppointment(props) {
  const {
    t,
    branch,
    data,
    fetchAvailableDates,
    setData,
  } = props;
  const items = branch?.appointments.map((item) => ({
    ...item,
    date: DateTime.fromISO(item.date)
      .toJSDate(),
  })) ?? [];

  const handleSelectBranch = () => {
    fetchAvailableDates({
      variables: {
        calendar: branch?.calendarId,
        services: data.selectedServices,
      },
    });
    setData({
      ...data,
      branch,
    });
  };

  return (
    <ListItem button onClick={handleSelectBranch}>
      <ListItemIcon>
        <FiMapPin
          style={{
            width: 18,
            height: 18,
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={branch.name}
        primaryTypographyProps={{
          variant: 'subtitle1',
        }}
        secondary={(
          <>
            {t('components.organisms.WorkShopAppointmentTool.steps.Appointment.datesFrom')}
            {' '}
            <Typography component="span" variant="subtitle2">
              {DateTime.fromJSDate(items[0]?.date)
                .setLocale('de')
                .toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          </>
        )}
      />
    </ListItem>
  );
}

Appointment.validateStep = function (data) {
  return data.selectedDate && data.selectedTime;
};

const FETCH_AVAILABLE_DATES = gql`
  query GetDates($calendar: ID!, $services: [ID]!) {
    workshopCapacities(calendar: $calendar, services: $services) {
      dates {
        date
        free_aw
      }
      aw
    }
  }
`;

const FETCH_TIMESLOTS = gql`
  query GetTimeSlots($calendar: ID!, $date: String!, $aw: Int!) {
    workshopTimeSlots(calendar: $calendar, date: $date, aw: $aw)
  }
`;

const FETCH_BRANCHES = gql`
  query GetItems($locale: I18NLocaleCode!) {
    branches(locale: $locale) {
      data {
        id
        attributes {
          name
          businessHoursWorkshop {
            id
            dayRangeStart
            dayRangeEnd
          }
        }
      }
    }
  }
`;

const FETCH_CALENDARS = gql`
  query GetItems($branchId: ID!, $name: String!, $locale: I18NLocaleCode!) {
    workshopCalendars(
      filters: {
        branch: {
          id: { eq: $branchId }
        },
        name: { eq: $name }
      },
      locale: $locale,
      pagination: { limit: 1 }
    ) {
      data {
        id
        attributes {
          name
          description
          icon {
            data {
              id
              attributes {
                name
                formats
                url
              }
            }
          }
        }
      }
    }
  }
`;
