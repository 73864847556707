import * as yup from 'yup';
import {
  Box,
  Button,
  Grid2,
  Paper,
  Typography,
  Alert,
} from '@mui/material';
import { DateTime } from 'luxon';
import { Formik } from 'formik';
import TextInput from '@components/atoms/TextInput';
import { formikInjectedPropsTypes } from '@forms/propTypes';
import { useEffect, useState } from 'react';
import { trackEvent } from '@components/organisms/Tracking';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSendEmail } from '@lib';
import Hidden from '@components/atoms/Hidden';

export default function Confirmation({
  t,
  data,
  setData,
  onReset,
}) {
  const sendEmail = useSendEmail();

  const [notificationSent, setNotificationSent] = useState(false);
  const { data: calendarData } = useQuery(FETCH_CALENDAR, {
    variables: {
      id: data.calendarId,
      serviceIds: data.selectedServices,
    },
  });
  const [getNewSmsCode] = useMutation(GET_NEW_SMS_CODE);
  const [createBooking] = useMutation(CREATE_BOOKING);

  useEffect(() => {
    trackEvent('Lead', { content_name: 'Workshop Appointment Tool' });
    trackEvent('LeadCustom', {
      step: 'Confirmation',
      origin: 'Workshop Appointment Tool',
    });
  }, []);

  useEffect(() => {
    if (notificationSent || !calendarData || !data.smsCodeValid) {
      return;
    }

    const {
      name,
      sendMailToWorkshop,
      notificationRecipients,
    } = calendarData.workshopCalendar.data.attributes;
    const services = calendarData.workshopServices.data.map((service) => service.attributes.wtpId);
    setNotificationSent(true);

    if (sendMailToWorkshop) {
      const mailData = {
        to: notificationRecipients.map((recipient) => recipient.email),
        bcc: ['info@dekay.dev', 'katrin.goerlitz@schimmel-automobile.de'],
        templateId: 'd-59dda663f4f14ec9a7ed16b2716c4883',
        dynamicTemplateData: {
          ...data,
          calendarName: name,
          services,
          mobility: data.mobility.title,
          date: DateTime.fromJSDate(data.selectedDate)
            .setLocale('de')
            .toLocaleString(DateTime.DATE_SHORT),
          time: data.selectedTime,
          request: {
            ...data.request,
            dateOfFirstRegistration: data.request.dateOfFirstRegistration
              ? DateTime.fromJSDate(data.request.dateOfFirstRegistration)
                .setLocale('de')
                .toLocaleString(DateTime.DATE_SHORT)
              : null,
          },
        },
      };

      sendEmail(mailData)
        .catch(console.error);
    }

    createBooking({
      variables: {
        data: {
          calendar: data.calendarId,
          requestDate: DateTime.now()
            .toISO(),
          bookingDate: DateTime.fromJSDate(data.selectedDate)
            .toISODate(),
          bookingTime: `${data.selectedTime}:00`,
          mobility: data.mobility.title,
          services: services.join('\n'),
          // Customer data
          name: data.request.name,
          email: data.request.email,
          phone: data.request.phone,
          licensePlate: data.request.licensePlate,
          model: data.request.model,
          vin: data.request.vin,
          message: data.request.message,
          dateOfFirstRegistration: data.request.dateOfFirstRegistration
            ? DateTime.fromJSDate(data.request.dateOfFirstRegistration)
              .toISODate()
            : null,
        },
      },
    }).catch(console.error);
  }, [createBooking, sendEmail, calendarData, notificationSent, data]);

  const handleGetNewSmsCode = () => getNewSmsCode({
    variables: {
      calendar: data.calendarId,
      appointmentToken: data.appointmentToken,
    },
  });

  return (
    <div>
      <Box mb={3}>
        <Typography variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.Confirmation.title')}
        </Typography>
      </Box>
      {data.smsCodeValid ? (
        <Paper>
          <Box p={1}>
            <Alert
              action={(
                <Hidden dir="down" size="sm">
                  <Button onClick={onReset}>
                    {t('components.organisms.WorkShopAppointmentTool.steps.Confirmation.bookWorkshopAppointment')}
                  </Button>
                </Hidden>
              )}
              severity="success"
            >
              {t('components.organisms.WorkShopAppointmentTool.steps.Confirmation.thankYouMessage', {
                category: data.calendar.attributes.name,
              })}
            </Alert>
          </Box>
        </Paper>
      ) : (
        <Formik
          initialValues={FORM_INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
        >
          {(formikBag) => (
            <Form
              {...formikBag}
              data={data}
              onRequestNewSms={handleGetNewSmsCode}
              setData={setData}
              t={t}
            />
          )}
        </Formik>
      )}
    </div>
  );
}

function Form(props) {
  const {
    t,
    data,
    setData,
    onRequestNewSms,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
  } = props;

  useEffect(() => {
    setData({
      ...data,
      smsCode: values.smsCode,
    });
  }, [values]);

  const handleRequestNewSmsCode = () => {
    setFieldValue('smsCode', '');
    onRequestNewSms();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid2 container spacing={3}>
        <Grid2 size={{ md: 6, xs: 12 }}>
          <Paper>
            <Box p={3}>
              <Box mb={1}>
                <Typography variant="subtitle2">
                  {t('components.organisms.WorkShopAppointmentTool.steps.Confirmation.form.description')}
                </Typography>
              </Box>
              <Box mb={1}>
                <TextInput
                  error={errors.smsCode && touched.smsCode}
                  fullWidth
                  helperText={errors.smsCode}
                  label="Bestätigungscode"
                  name="smsCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.smsCode}
                />
              </Box>
              {data.smsCodeValid === false && (
                <Alert severity="error">
                  {t('components.organisms.WorkShopAppointmentTool.steps.Confirmation.form.alert.content')}
                </Alert>
              )}
              <Box mt={3}>
                <Button
                  fullWidth
                  onClick={handleRequestNewSmsCode}
                >
                  {t('components.organisms.WorkShopAppointmentTool.steps.Confirmation.form.newConfirmCode')}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid2>
      </Grid2>
    </form>
  );
}

Form.propTypes = {
  ...formikInjectedPropsTypes,
};

Confirmation.validateStep = function (data) {
  return data.appointmentToken && VALIDATION_SCHEMA.isValidSync({
    smsCode: data.smsCode,
  });
};

const FORM_INITIAL_VALUES = {
  smsCode: '',
};

const VALIDATION_SCHEMA = yup.object()
  .shape({
    smsCode: yup.string()
      .min(6, 'Der Bestätigungscode ist genau 6 Zeichen lang.')
      .max(6, 'Der Bestätigungscode ist genau 6 Zeichen lang.')
      .required('Bitten geben Sie hier den Bestätigungscode ein.'),
  });

const FETCH_CALENDAR = gql`
  query GetItem($id: ID!, $serviceIds: [ID!]!) {
    workshopCalendar(id: $id) {
      data {
        id
        attributes {
          name
          sendMailToWorkshop
          notificationRecipients {
            id
            email
          }
        }
      }
    }
    workshopServices(
      sort: ["wtpId"],
      filters: {
        id: { in: $serviceIds}
      }
    ) {
      data {
        id
        attributes {
          wtpId
        }
      }
    }
  }
`;

const GET_NEW_SMS_CODE = gql`
  mutation GetNewSmsCode($calendar: ID!, $appointmentToken: String!) {
    workshopGetNewSmsCode(calendar: $calendar, appointmentToken: $appointmentToken)
  }
`;

const CREATE_BOOKING = gql`
  mutation CreateBooking($data: WorkshopBookingInput!) {
    createWorkshopBooking(data: $data) {
      data {
        id
      }
    }
  }
`;
