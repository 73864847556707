import * as yup from 'yup';
import { Box, useTheme } from '@mui/material';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import { useConsent } from '@lib/gdpr';
import ConsentRequiredFallback from '../molecules/ConsentRequiredFallback';

export default function FrameBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const hasConsent = useConsent('googleMaps');
  const { elementData } = useContentElement(
    data,
    FrameBlock.dataSchema,
  );

  let title = null;
  const height = elementData.height ?? 400;

  if (!hasConsent) {
    return <ConsentRequiredFallback />;
  }

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
      }}
    >
      {title && (
        <Box mb={5}>
          <TypographyElement data={title} />
        </Box>
      )}
      {hasConsent && (
        <iframe
          allowFullScreen="no"
          height={height}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          src={elementData.url}
          style={{ border: 0, width: '100%', height: `${height}px` }}
          title="Frame"
          width="100%"
        />
      )}
    </Box>
  );
}

FrameBlock.typeName = 'ComponentContentFrame'; // Strapi element type

FrameBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    url: PropTypes.string,
    height: PropTypes.number,
  }).isRequired,
};
FrameBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema.nullable(),
  url: yup.string().required(),
  height: yup.number().nullable(),
});
FrameBlock.graphQlSchema = `
... on ${FrameBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  url
  height
}
`;
