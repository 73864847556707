import { dateTimeField } from '@lib/form';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';

export default function DateTimeField(props) {
  const { type } = props;

  switch (type) {
    case 'time':
      return <TimeField {...props} />;
    case 'date':
      return <DateField {...props} />;
    case 'datetime':
      return <DateAndTimeField {...props} />;
    default:
      throw new Error(`Type '${type}' not supported.`);
  }
}

DateTimeField.propTypes = {
  type: PropTypes.oneOf(['date', 'datetime', 'time']).isRequired,
};

function TimeField(props) {
  const {
    disabled,
    value = '',
    error,
    touched,
    label = '',
    handleBlur,
    handleChange,
    readOnly,
  } = props;

  return (
    <TextField
      disabled={disabled}
      error={error && touched}
      fullWidth
      helperText={error}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly,
      }}
      label={label}
      onBlur={handleBlur}
      onChange={(newValue) => handleChange(DateTime.fromISO(newValue.target.value))}
      size="small"
      type="time"
      value={value?.toISOTime()}
    />
  );
}

TimeField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

function DateField(props) {
  const {
    disabled,
    value = '',
    error,
    touched,
    label = '',
    handleBlur,
    name,
    handleChange,
    helperText = '',
    readOnly,
  } = props;

  return (
    <TextField
      disabled={disabled}
      error={error && touched}
      fullWidth
      helperText={error || helperText}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly,
      }}
      label={label}
      onBlur={handleBlur}
      onChange={(newValue) => handleChange(DateTime.fromISO(newValue.target.value))}
      size="small"
      type="date"
      value={value?.toISODate()}
    />
  );
}

DateField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

function DateAndTimeField(props) {
  const {
    disabled,
    value = '',
    error,
    touched,
    label,
    handleBlur,
    handleChange,
    readOnly,
  } = props;

  return (
    <TextField
      disabled={disabled}
      error={error && touched}
      fullWidth
      helperText={error}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly,
      }}
      label={label}
      onBlur={handleBlur}
      onChange={(newValue) => handleChange(DateTime.fromISO(newValue.target.value))}
      size="small"
      type="datetime-local"
      value={value?.toISO()}
    />
  );
}

DateAndTimeField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  label: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

DateTimeField.getDefaultValue = dateTimeField.getDefaultValue;
DateTimeField.serializeValue = dateTimeField.serializeValue;
DateTimeField.deserializeValue = dateTimeField.deserializeValue;
DateTimeField.getDisplayValue = dateTimeField.getDisplayValue;
DateTimeField.validationSchema = dateTimeField.validationSchema;
DateTimeField.graphQlSchema = `
typeDateTime {
  id
  type
}
`;
