import { Alert } from '@mui/material';
import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { helper } from '@lib/form';
import { FORM_FIELDS } from './fieldMapping';

const DEBOUNCE_TIMEOUT = 500;

function FormField(props) {
  const {
    id,
    values,
    errors,
    touched,
    type,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    label,
    isRequired,
    isDisabled,
    isReadOnly,
    helperText,
  } = props;

  // Handle value & updates
  const formikValue = values[id];

  const [value, setValue] = useState(formikValue);
  useEffect(() => {
    setValue(formikValue);
  }, [setValue, formikValue]);

  // Handle changes
  const setFieldValueDebounced = useMemo(
    () => debounce(setFieldValue, DEBOUNCE_TIMEOUT),
    [setFieldValue],
  );
  const handleChange = useCallback((newValue) => {
    setValue(newValue);
    setFieldValueDebounced(id, newValue);
  }, [setValue, setFieldValueDebounced, id]);

  const field = type;
  // eslint-disable-next-line no-underscore-dangle
  const fieldType = field?.__typename;
  const FieldComponent = FORM_FIELDS[fieldType];

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {type ? (
        <FieldComponent
          allTouched={touched}
          disabled={isDisabled}
          error={errors[id]}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          helperText={helperText}
          label={helper.getRequiredField(label, isRequired)}
          name={id}
          readOnly={isReadOnly}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched[id]}
          value={value}
          values={values}
          {...field}
        />
      ) : (
        <Alert severity="error">
          Kein Feldtyp ausgewählt.
        </Alert>
      )}
    </>
  );
}

FormField.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  type: PropTypes.array.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  helperText: PropTypes.string,
};

const FormFieldMemo = memo(FormField);
export default FormFieldMemo;
