import TextField from '@components/molecules/form/fields/TextField';
import SelectField from '@components/molecules/form/fields/SelectField';
import DateTimeField from '@components/molecules/form/fields/DateTimeField';
import CheckBoxField from '@components/molecules/form/fields/CheckBoxField';

export const FORM_FIELDS = {
  ComponentFormText: TextField,
  ComponentFormSelect: SelectField,
  ComponentFormDateTime: DateTimeField,
  ComponentFormCheckbox: CheckBoxField,
};
