import * as yup from 'yup';
import { Fragment } from 'react';
import {
  Box,
  Grid2,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ButtonElement,
  TypographyElement,
  MediaElement,
  useContentElement,
} from '@plugins/next-cms-core';
import Countdown from 'react-countdown';
import { FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import { useTranslation } from 'react-i18next';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { DateTime } from 'luxon';
import Container from '../atoms/Container';

export default function CountdownBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const { elementData } = useContentElement(
    data,
    CountdownBlock.dataSchema,
  );

  const { date, buttons, background } = elementData;
  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
    textAlign: 'center',
  });

  const text = omitBy(elementData.text, isNull);
  defaults(text, {
    semanticVariant: 'div',
    displayVariant: 'body1',
    textAlign: 'center',
  });

  const isExpired = DateTime.fromISO(date) < Date.now();

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <MediaElement
        data={background}
        isFluid={false}
        isSquare
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      />
      <Container
        sx={{
          zIndex: 2,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: theme.spacing(10),
          paddingBottom: theme.spacing(10),
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Box mb={text.value ? 3 : 0}>
            <TypographyElement
              color="secondary"
              data={title}
              sx={{
                '& p': {
                  margin: 0,
                },
              }}
            />
          </Box>
          {text.value && (
            <Grid2
              container
              justifyContent="center"
              sx={{
                [theme.breakpoints.up('md')]: {
                  paddingLeft: theme.spacing(9),
                },
              }}
            >
              <Grid2 size={{ lg: 9, md: 10, xs: 12 }}>
                <TypographyElement
                  color="secondary"
                  data={text}
                />
              </Grid2>
            </Grid2>
          )}
          <Box mb={2} mt={2}>
            {isExpired ? (
              <Typography
                align="center"
                color="secondary"
                variant="h6"
              >
                {t('components.contentTypes.CountDownBlock.actionExpired')}
              </Typography>
            ) : (
              <Countdown
                date={date}
                renderer={({
                  days: totalDays, hours, minutes, seconds, completed,
                }) => {
                  if (completed) {
                    return null;
                  }

                  const parts = [];
                  const weeks = Math.floor(totalDays / 7);
                  const days = totalDays % 7;

                  if (weeks > 0) {
                    parts.push({
                      value: weeks,
                      label: t(`components.contentTypes.CountDownBlock.${weeks === 1 ? 'week' : 'weeks'}`),
                    });
                  }

                  parts.push({
                    value: days,
                    label: t(`components.contentTypes.CountDownBlock.${days === 1 ? 'day' : 'days'}`),
                  });

                  parts.push({
                    value: hours,
                    label: t(`components.contentTypes.CountDownBlock.${hours === 1 ? 'hour' : 'hours'}`),
                  });

                  parts.push({
                    value: minutes,
                    label: t(`components.contentTypes.CountDownBlock.${minutes === 1 ? 'minute' : 'minutes'}`),
                  });

                  // parts.push({
                  //   value: seconds,
                  // eslint-disable-next-line max-len
                  //   label: t(`components.contentTypes.CountDownBlock.${seconds === 1 ? 'second' : 'seconds'}`),
                  // });

                  return (
                    <Grid2
                      container
                      justifyContent="center"
                      spacing={3}
                    >
                      {parts.map((part, index) => (
                        <Fragment key={part.label}>
                          <Grid2>
                            <Typography
                              align="center"
                              color="secondary"
                              component="div"
                              variant="h1"
                            >
                              {part.value}
                            </Typography>
                            <Typography
                              align="center"
                              color="secondary"
                              component="div"
                              variant="subtitle2"
                            >
                              {part.label}
                            </Typography>
                          </Grid2>
                          {index < parts.length - 1 && (
                            <Grid2>
                              <Typography
                                align="center"
                                color="secondary"
                                component="div"
                                variant="h1"
                              >
                                :
                              </Typography>
                            </Grid2>
                          )}
                        </Fragment>
                      ))}
                    </Grid2>
                  );
                }}
              />
            )}
          </Box>
          {buttons && buttons.length > 0 && (
            <Box mt={3}>
              <Grid2
                container
                justifyContent="center"
                spacing={3}
              >
                {buttons.map((button) => (
                  <Grid2
                    key={button.id}
                    size={{ md: 'auto', xs: 12 }}
                  >
                    <ButtonElement
                      color="secondary"
                      component="a"
                      data={button}
                      endIcon={<FiArrowRight />}
                      fullWidth
                      size="large"
                      variant="outlined"
                    />
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}

CountdownBlock.typeName = 'ComponentContentCountdown'; // Strapi element type
CountdownBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes)),
    media: PropTypes.shape(MediaElement.propTypes),
  }).isRequired,
};
CountdownBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema,
  text: TypographyElement.dataSchema.nullable(),
  buttons: yup.array(ButtonElement.dataSchema).nullable(),
  media: MediaElement.dataSchema.nullable(),
});
CountdownBlock.graphQlSchema = `
... on ${CountdownBlock.typeName} {
  id
  date
  title {
    ${TypographyElement.graphQlSchema}
  }
  text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
  background {
    ${MediaElement.graphQlSchema}
  }
}
`;
