/* eslint-disable prefer-const,react/require-default-props */
import {
  Button, Grid2, MenuItem, Paper, Tab, Tabs, useTheme,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import Image from '@components/atoms/Image2';
import { useEffect, useState } from 'react';
import { FiArrowRightCircle } from 'react-icons/fi';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useTranslation } from 'react-i18next';
import { FaCarSide, FaTruck, FaVanShuttle } from 'react-icons/fa6';
import Link from '@components/atoms/Link';
import Hidden from '@components/atoms/Hidden';
import Select from '@components/atoms/Select';
import { transformBrandTitle } from '../vehiclefleet/CarBrandTitle';

export default function CarSearchFilterBar(props) {
  const {
    translateY,
    preSelectedBrand,
    preSelectedBranch,
    isMobile,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const [cachedTotalCount, setTotalCount] = useState(0);

  const {
    error,
    data,
    refetch,
  } = useQuery(QUERY_CAR_SEARCH_FILTER_DATA, {
    variables: {
      brand: preSelectedBrand,
      branch: preSelectedBranch,
    },
  });

  if (error) {
    throw error;
  }

  let {
    // Filter data
    categories = [],
    brands = [],
    models = [],
    // Selection
    category = ['all'],
    brand,
    model,
    branch,
  } = data?.carSearchFilterData ?? {};
  const {
    totalCount,
  } = data?.carSearchResultCount ?? {
    totalCount: cachedTotalCount,
  };

  const searchQuery = new URLSearchParams(omitBy({
    category,
    brand,
    model,
    branch,
  }, isNil));
  const searchUrl = `/fahrzeugsuche?${searchQuery.toString()}`;

  useEffect(() => {
    setTotalCount(totalCount);
  }, [totalCount]);

  const handleChangeVehicleCategory = (useValue = true) => (e, value) => {
    const vehicleCategory = useValue ? value : e.target.value;
    refetch({
      category: vehicleCategory.split('*'),
      brand: null,
      model: null,
    });
  };
  const handleChangeVehicleBrand = (e) => {
    const vehicleBrand = e.target.value;
    refetch({
      category,
      brand: vehicleBrand,
      model: null,
    });
  };
  const handleChangeVehicleModel = (e) => {
    const vehicleModel = e.target.value;
    refetch({
      category,
      brand,
      model: vehicleModel,
    });
  };

  if (!categories || categories.length === 0) {
    categories = [
      'all',
      'alternative-drives',
      'compact',
      'sedan',
      'wagon',
      'suv',
      'van',
      'truck',
    ];
  }

  return (
    <Paper
      elevation={10}
      sx={{
        backgroundColor: `${theme.palette.primary.main} !important`,
        flex: 1,
        marginBottom: 2,
        marginTop: translateY ? '-85px' : 2,
        padding: 2,
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Grid2 alignItems="center" container spacing={2}>
        <Grid2 size={12}>
          <VehicleCategory
            onChange={handleChangeVehicleCategory}
            t={t}
            theme={theme}
            vehicleCategories={categories}
            vehicleCategory={category}
          />
        </Grid2>
        <Grid2
          size={{
            xs: isMobile ? 6 : 12,
            md: 3,
            sm: 6,
          }}
        >
          <Brand
            brand={brand}
            brands={brands}
            onChange={handleChangeVehicleBrand}
            t={t}
          />
        </Grid2>
        <Grid2
          size={{
            xs: isMobile ? 6 : 12,
            md: 3,
            sm: 6,
          }}
        >
          <Model
            model={model}
            models={models}
            onChange={handleChangeVehicleModel}
            t={t}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 3,
            sm: 6,
          }}
        >
          <Link href={searchUrl} legacyBehavior>
            <Button
              color="secondary"
              component="a"
              endIcon={<FiArrowRightCircle />}
              fullWidth
            >
              {t('components.organisms.CarSearchFilterBar.detailedSearch')}
            </Button>
          </Link>
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 3,
            sm: 6,
          }}
        >
          <Link href={searchUrl} legacyBehavior>
            <Button
              color="secondary"
              component="a"
              fullWidth
              variant="contained"
            >
              {totalCount}
              {' '}
              {t('components.organisms.CarSearchFilterBar.showVehicles')}
            </Button>
          </Link>
        </Grid2>
      </Grid2>
    </Paper>
  );
}

CarSearchFilterBar.propTypes = {
  translateY: PropTypes.bool,
  preSelectedBrand: PropTypes.string,
  preSelectedBranch: PropTypes.string,
};

function VehicleCategory(props) {
  const {
    t,
    vehicleCategories,
    vehicleCategory,
    onChange,
    theme,
  } = props;

  return (
    <>
      <Hidden dir="down" size="md">
        <Tabs
          onChange={onChange(true)}
          sx={{
            '& .MuiTab-root': {
              flex: 1,
              minWidth: 0,
              minHeight: 0,
              padding: theme.spacing(0.5),
              borderRadius: `${theme.shape.borderRadius}px`,
              marginRight: theme.spacing(2),
              borderWidth: 2,
              borderColor: 'transparent',
              borderStyle: 'solid',
              color: theme.palette.primary.contrastText,
              textTransform: 'none',
              opacity: 0.7,
            },
            '& .MuiTab-root.Mui-selected': {
              borderColor: theme.palette.secondary.main,
              backgroundColor: 'rgba(255,255,255,0.15)',
              color: theme.palette.primary.contrastText,
              opacity: 1,
            },
          }}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          value={vehicleCategory.join('*') ?? 'all'}
          variant="scrollable"
        >
          {vehicleCategories.map((category) => (
            <Tab
              key={category}
              icon={(
                <Image
                  alt={`${t(`enums.vehicleCategory.${category || 'unknown'}`)} Icon`}
                  height={50}
                  src={`/images/icons/car-type-${category}-${vehicleCategory[0] === category ? 'primaryContrast' : 'primaryContrast'}.svg`}
                  style={mapVehicleIconSize(category)}
                  width={100}
                />
              )}
              label={t(`enums.vehicleCategory.${category || 'unknown'}`)}
              value={category ?? null}
            />
          ))}
        </Tabs>
      </Hidden>
      <Hidden dir="up" size="md">
        <Tabs
          onChange={onChange(true)}
          sx={{
            '& .MuiTabs-flexContainer': {
              borderWidth: 2,
              borderColor: 'rgba(255,255,255,0.70)',
              borderStyle: 'solid',
              borderRadius: `${theme.shape.borderRadius}px`,
            },
            '& .MuiTab-root': {
              flex: 1,
              minWidth: 0,
              minHeight: 0,
              padding: theme.spacing(0.5),
              borderRight: 2,
              borderColor: 'rgba(255,255,255,0.70)',
              borderRightStyle: 'solid',
              color: 'rgba(255,255,255,0.70)',
              '&:last-child': {
                borderRight: 0,
              },
            },
            '& .MuiTab-root.Mui-selected': {
              backgroundColor: 'rgba(255,255,255,0.15)',
              color: 'white',
            },
          }}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          value={vehicleCategory.join('*') ?? 'all'}
          variant="scrollable"
        >
          {VEHICLE_CATEGORIES.map((category) => (
            <Tab
              key={category.name}
              aria-label="category button"
              icon={category.icon}
              value={category.value.join('*') ?? null}
            />
          ))}
        </Tabs>
      </Hidden>
    </>
  );
}

function Brand(props) {
  const {
    t,
    brands,
    brand,
    onChange,
  } = props;

  return (
    <Select
      color="secondary"
      label={t('components.organisms.CarSearchFilterBar.brand')}
      onChange={onChange}
      value={brand ?? ''}
      variant="outlined"
    >
      <MenuItem key="empty" value={null}>
        {t('components.organisms.CarSearchFilterBar.allBrands')}
      </MenuItem>
      {brands.map((item) => (
        <MenuItem key={item} value={item}>
          {transformBrandTitle(item)}
        </MenuItem>
      ))}
    </Select>
  );
}

function Model(props) {
  const {
    t,
    models,
    model,
    onChange,
  } = props;

  return (
    <Select
      color="secondary"
      label={t('components.organisms.CarSearchFilterBar.model')}
      onChange={onChange}
      value={model ?? ''}
      variant="outlined"
    >
      <MenuItem key="empty" value={null}>
        {t('components.organisms.CarSearchFilterBar.allModels')}
      </MenuItem>
      {models.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
}

/*
function PriceRange({ classes }) {
  const [priceRange, setPriceRange] = useState([0, 50000]);

  return (
    <div className={classes.formElement}>
      <Typography variant="caption" color="secondary">Preis</Typography>
      <Box display="flex" alignItems="center">
        <Box mr={2} width={50} flexShrink={0}>
          <Typography noWrap variant="caption" color="secondary">
            {`${formatNumber(priceRange[0])} €`}
          </Typography>
        </Box>
        <Slider
          min={0}
          max={50000}
          step={1000}
          value={priceRange}
          onChange={(e, value) => setPriceRange(value)}
          valueLabelDisplay="off"
          valueLabelFormat={(x) => `${x} €`}
          color="secondary"
        />
        <Box ml={2} width={50} flexShrink={0}>
          <Typography noWrap variant="caption" color="secondary">
            {`${formatNumber(priceRange[1])} €`}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

function formatNumber(value){
  return value.toLocaleString('en-US').replace(',', '.');
}
*/

const VEHICLE_CATEGORIES = [
  {
    name: 'cars',
    icon: <FaCarSide size={30} />,
    value: ['compact', 'sedan', 'wagon', 'suv'],
  },
  {
    name: 'vans',
    icon: <FaVanShuttle size={30} />,
    value: ['van', 'bus'],
  },
  {
    name: 'trucks',
    icon: <FaTruck size={30} />,
    value: ['truck'],
  },
];

function mapVehicleIconSize(value) {
  const factor = 0.85;

  switch (value) {
    case 'all':
      return {
        width: 100 * factor,
        height: 50 * factor,
      };
    case 'compact':
      return {
        width: 50 * factor,
        height: 40 * factor,
      };
    case 'alternative-drives':
      return {
        width: 100 * factor,
        height: 50 * factor,
      };
    case 'sedan':
      return {
        width: 100 * factor,
        height: 50 * factor,
      };
    case 'wagon':
      return {
        width: 90 * factor,
        height: 50 * factor,
      };
    case 'suv':
      return {
        width: 90 * factor,
        height: 56 * factor,
      };
    case 'van':
      return {
        width: 100 * factor,
        height: 50 * factor,
      };
    default:
      return null;
  }
}

export const QUERY_CAR_SEARCH_FILTER_DATA = gql`
  query GetSearchFilterData($category: [String], $brand: String, $model: String, $branch: ID) {
    carSearchFilterData(category: $category, brand: $brand, model: $model, branch: $branch) {
      categories
      category
      brands
      brand
      models
      model
      branch
    }
    carSearchResultCount(category: $category, brand: $brand, model: $model, branch: $branch) {
      totalCount
    }
  }
`;
