import {
  Box,
  List,
  ListItem, ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import Image from '@components/atoms/Image';

export default function Mobility({ t, data, setData }) {
  const theme = useTheme();
  const selectedMobility = data.mobility;

  const handleToggleMobility = (mobility) => () => {
    setData({
      ...data,
      mobility,
    });
  };

  return (
    <div>
      <Box mb={3}>
        <Typography variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.Mobility.question')}
        </Typography>
      </Box>
      <Paper>
        <Box px={1} py={0.5}>
          <List disablePadding>
            {OPTIONS.map((option) => (
              <ListItemButton
                key={option.id}
                data-e2e-id="mobility"
                onClick={handleToggleMobility(option)}
                sx={{
                  borderRadius: `${theme.shape.borderRadius}px`,
                  marginTop: theme.spacing(0.5),
                  marginBottom: theme.spacing(0.5),
                  backgroundColor: selectedMobility?.id === option.id ? theme.palette.primary.main : 'transparent',
                  '&:focus, &:active': {
                    backgroundColor: selectedMobility?.id === option.id ? theme.palette.primary.main : 'transparent',
                  },
                }}
              >
                <ListItemIcon>
                  {option.icon}
                </ListItemIcon>
                <ListItemText>
                  {t(`components.organisms.WorkShopAppointmentTool.steps.Mobility.${option.id}`)}
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Paper>
    </div>
  );
}

Mobility.validateStep = function (data) {
  return Boolean(data.mobility);
};

const OPTIONS = [
  {
    id: 'no-mobility',
    title: 'Keine Mobilität',
    icon: <Image alt="" height="24" src="/images/icons/icon-mobility-none.svg" styles={{ display: 'block' }} width="24" />,
  },
  {
    id: 'replacement-car',
    title: 'Ersatzwagen',
    icon: <Image alt="" height="24" src="/images/icons/icon-mobility-replacement-car.svg" styles={{ display: 'block' }} width="24" />,
  },
];
