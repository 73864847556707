const yup = require('yup');

function getDisplayValue(value) {
  if (typeof value === 'object') {
    return value.value ? 'Ja' : 'Nein';
  }
  return value ? 'Ja' : 'Nein';
}

module.exports = {
  getDefaultValue: () => false,
  serializeValue: (value) => ({ value }),
  deserializeValue: (state) => state.value,
  getDisplayValue,
  validationSchema: () => yup.boolean().nullable(),
};
