import * as yup from 'yup';
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import defaults from 'lodash/defaults';
import Container from '@components/atoms/Container';
import { useConsent } from '@lib/gdpr';
import { useEffect } from 'react';

export default function VehicleConfiguratorBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const hasConsent = useConsent('bdk');
  const { elementData } = useContentElement(
    data,
    VehicleConfiguratorBlock.dataSchema,
  );

  const {
    brand,
    description,
  } = elementData;
  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  useEffect(() => {
    if (!hasConsent) {
      return;
    }

    window.location.hash = `#/${brand}`;
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://snippets.bdk-konfigurator.de/xQXtht6JMBV60g';
    script.onload = function () {
      script.onload = null;
      script.onreadystatechange = null;
      // eslint-disable-next-line no-unused-expressions
      window.callback && window.callback();
    };
    script.onreadystatechange = script.onload;

    const element = document.getElementById('car-config-wrapper');
    element.appendChild(script);

    // eslint-disable-next-line consistent-return
    return () => {
      script.remove();
    };
  }, [brand, hasConsent]);

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
      }}
    >
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        {description && (
          <Box mb={5}>
            <TypographyElement data={description} />
          </Box>
        )}
        <Box>
          <div id="car-config-wrapper" />
          <iframe
            data-name="bdk"
            data-src="about:blank"
            style={{ border: 0 }}
            title="bdk"
          />
        </Box>
      </Container>
    </Box>
  );
}

VehicleConfiguratorBlock.typeName = 'ComponentContentVehicleConfigurator';
VehicleConfiguratorBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    description: TypographyElement.propTypes,
    brand: PropTypes.string,
  }).isRequired,
};
VehicleConfiguratorBlock.dataSchema = yup.object()
  .shape({
    id: yup.number()
      .required(),
    title: TypographyElement.dataSchema.nullable(),
    description: TypographyElement.dataSchema.nullable(),
    brand: yup.string()
      .nullable(),
  });
VehicleConfiguratorBlock.graphQlSchema = `
... on ${VehicleConfiguratorBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  description {
    ${TypographyElement.graphQlSchema}
  }
  brand
}
`;
