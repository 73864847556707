import {
  Box,
  Button,
  Divider,
  Grid2,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  FiCalendar, FiCheckCircle, FiClock, FiMapPin,
} from 'react-icons/fi';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '@components/organisms/Tracking';
import Container from '@components/atoms/Container';
import Hidden from '@components/atoms/Hidden';
import AppointmentStep from './steps/Appointment';
import BranchesStep from './steps/Branches';
import CalendarStep from './steps/Calendar';
import ConfirmationStep from './steps/Confirmation';
import MobilityStep from './steps/Mobility';
import PersonalDetailsStep from './steps/PersonalDetails';
import ServicesStep from './steps/Services';

export default function WorkshopAppointmentTool(props) {
  const { branch } = props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isLoading, setLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(branch ? 1 : 0);
  const [navigationDirection, setNavigationDirection] = useState('forward');
  const [isAdditionalServices, setIsAdditionalServices] = useState(false);
  const [stepValid, setStepValid] = useState({});
  const [data, setData] = useState({
    branch: {
      id: branch?.id ?? null,
      ...branch?.attributes,
    },
    categories: [],
    selectedServices: [],
  });
  const [createAppointment] = useMutation(CREATE_APPOINTMENT);
  const [checkSmsCode] = useMutation(CHECK_SMS_CODE);
  const isActiveStepValid = Boolean(stepValid[activeStep]);
  const isFinalStep = activeStep >= STEPS.length - 1;
  const Component = STEPS[activeStep].component;

  useEffect(() => {
    trackEvent('Schedule');
    trackEvent('ScheduleCustom', { step: 'Initial', origin: 'Workshop Appointment Tool' });
  }, []);
  useEffect(() => {
    setStepValid({
      ...stepValid,
      [activeStep]: Component.validateStep(data),
    });
  }, [data, activeStep]);

  const handleReset = () => {
    setData({
      categories: [],
      selectedServices: [],
    });
    setActiveStep(0);
  };
  const handleGoPreviousStep = () => {
    if (activeStep <= 0) {
      return;
    }

    if (activeStep === 2 && isAdditionalServices) {
      setIsAdditionalServices(false);
      return;
    }

    scrollToTarget();
    setNavigationDirection('backward');
    setActiveStep(activeStep - 1);
  };
  const handleGoNextStep = async () => {
    if (activeStep + 1 >= STEPS.length) {
      return;
    }

    if (activeStep === STEPS.length - 2) {
      await handleSubmitForm();
    }

    if (activeStep === 2 && !isAdditionalServices) {
      setIsAdditionalServices(true);
      return;
    }

    scrollToTarget();
    setNavigationDirection('forward');
    setActiveStep(activeStep + 1);
  };
  const handleGoToConfirmationPage = () => {
    setData({
      ...data,
      smsCodeValid: true,
    });
    setActiveStep(STEPS.length - 1);
  };
  const scrollToTarget = () => {
    const id = 'wtp-scroll-target';
    const yOffset = -70;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

    if (!isInitialLoading) {
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (isInitialLoading) {
      window.addEventListener('click', () => {
        setIsInitialLoading(false);
      });
    } else {
      window.removeEventListener('click', () => {
        setIsInitialLoading(false);
      });
    }
  }, [isInitialLoading]);

  const handleSubmitForm = async () => {
    setLoading(true);

    try {
      const result = await createAppointment({
        variables: {
          calendar: data.calendarId,
          request: {
            date: DateTime.fromJSDate(data.selectedDate).toFormat('yyyy-MM-dd'),
            time: data.selectedTime,
            name: data.request.name,
            email: data.request.email,
            phone: data.request.phone,
            licensePlate: data.request.licensePlate,
            model: data.request.model,
            vin: data.request.vin,
            dateOfFirstRegistration: data.request.dateOfFirstRegistration
              ? DateTime
                .fromJSDate(data.request.dateOfFirstRegistration)
                .setLocale('de')
                .toLocaleString(DateTime.DATE_SHORT)
              : null,
            mobility: data.mobility.id,
            comment: data.request.message,
            services: data.selectedServices,
          },
        },
      });

      setData({
        ...data,
        appointmentToken: result.data.workshopCreateAppointment,
        smsCodeValid: null,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleConfirmSms = async () => {
    setLoading(true);

    try {
      const result = await checkSmsCode({
        variables: {
          calendar: data.calendarId,
          code: data.smsCode,
          appointmentToken: data.appointmentToken,
        },
      });

      setData({
        ...data,
        smsCodeValid: result.data.workshopCheckSmsCode,
      });
    } finally {
      setLoading(false);
    }
  };

  const nextButton = (
    <Button
      color="primary"
      data-e2e-id="next-button"
      disabled={isLoading || !isActiveStepValid}
      fullWidth
      onClick={isFinalStep ? handleConfirmSms : handleGoNextStep}
      variant="contained"
    >
      {t(`components.organisms.WorkShopAppointmentTool.${isFinalStep ? 'confirm' : 'next'}`)}
    </Button>
  );

  return (
    <Box>
      <div id="wtp-scroll-target" />
      <Hidden dir="down" size="lg">
        <Container>
          <Stepper
            activeStep={activeStep}
            alternativeLabel={isDesktop}
            orientation={isDesktop ? 'horizontal' : 'vertical'}
            sx={{
              padding: theme.spacing(3),
            }}
          >
            {STEPS.map((step) => (
              <Step key={step.key}>
                <StepLabel>
                  {t(`components.organisms.WorkShopAppointmentTool.steps.${step.key}`)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Container>
      </Hidden>
      <Box
        sx={{
          backgroundColor: theme.palette.quaternary.main,
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(5),
          '& .MuiListSubheader-root': {
            backgroundColor: 'unset',
          },
        }}
      >
        <Container>
          <Grid2 container spacing={3}>
            <Grid2
              size={{ md: 8, lg: 9 }}
              sx={{
                width: '100%',
              }}
            >
              <Component
                data={data}
                isAdditionalServices={isAdditionalServices}
                navigationDirection={navigationDirection}
                onGoNext={handleGoNextStep}
                onGoToConfirmationPage={handleGoToConfirmationPage}
                onRequestNewSms={handleSubmitForm}
                onReset={handleReset}
                setData={setData}
                t={t}
              />
              {activeStep > 0 && !data.smsCodeValid && (
                <Box mt={5}>
                  <Grid2 container direction={isMobile ? 'column-reverse' : 'row'} spacing={3}>
                    <Grid2 size={{ sm: 6, xs: 12 }}>
                      {activeStep > 0 && (
                        <Button
                          disabled={isLoading}
                          fullWidth
                          onClick={handleGoPreviousStep}
                        >
                          {t('components.organisms.WorkShopAppointmentTool.back')}
                        </Button>
                      )}
                    </Grid2>
                    <Grid2 size={{ sm: 6, xs: 12 }}>
                      {nextButton}
                    </Grid2>
                  </Grid2>
                </Box>
              )}
            </Grid2>
            <Grid2 size={{ lg: 3, md: 4 }}>
              <Box mb={3}>
                <Typography variant="h6">
                  {t('components.organisms.WorkShopAppointmentTool.yourChoice')}
                </Typography>
              </Box>
              {!data.branch?.id && (
                <List>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <FiMapPin />
                    </ListItemIcon>
                    <ListItemText
                      primary="Wählen Sie einen Standort"
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                </List>
              )}
              {data.branch?.id && (
                <List
                  subheader={(
                    <ListSubheader>
                      {t('components.organisms.WorkShopAppointmentTool.location')}
                    </ListSubheader>
                  )}
                >
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <FiMapPin />
                    </ListItemIcon>
                    <ListItemText
                      primary={data.branch.name}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                </List>
              )}
              {data.selectedServices.length > 0 && (
                <>
                  <Divider />
                  <List
                    subheader={(
                      <ListSubheader>
                        {t('components.organisms.WorkShopAppointmentTool.selectedServices')}
                      </ListSubheader>
                    )}
                  >
                    {data.selectedServices.map((id) => (
                      <Service
                        key={id}
                        service={findService(data.categories, id)}
                      />
                    ))}
                  </List>
                </>
              )}
              {data.mobility && (
                <>
                  <Divider />
                  <List
                    subheader={(
                      <ListSubheader>
                        {t('components.organisms.WorkShopAppointmentTool.mobility')}
                      </ListSubheader>
                    )}
                  >
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        {data.mobility.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={t(`components.organisms.WorkShopAppointmentTool.steps.Mobility.${data.mobility.id}`)}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>
                  </List>
                </>
              )}
              {data.selectedDate && (
                <>
                  <Divider />
                  <List
                    subheader={(
                      <ListSubheader>
                        {t('components.organisms.WorkShopAppointmentTool.date')}
                      </ListSubheader>
                    )}
                  >
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <FiCalendar />
                      </ListItemIcon>
                      <ListItemText
                        primary={DateTime.fromJSDate(data.selectedDate)
                          .setLocale(i18n.language).toLocaleString(DateTime.DATE_HUGE)}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>
                  </List>
                </>
              )}
              {data.selectedTime && (
                <>
                  <Divider />
                  <List
                    subheader={(
                      <ListSubheader>
                        {t('components.organisms.WorkShopAppointmentTool.time')}
                      </ListSubheader>
                    )}
                  >
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <FiClock />
                      </ListItemIcon>
                      <ListItemText
                        primary={data.selectedTime}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>
                  </List>
                </>
              )}
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </Box>
  );
}

function Service({ service }) {
  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth: 32 }}>
        <FiCheckCircle />
      </ListItemIcon>
      <ListItemText
        primary={service?.attributes.name ?? '-'}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  );
}

const STEPS = [
  {
    key: 'branches',
    label: 'Standort',
    component: BranchesStep,
  },
  {
    key: 'calendar',
    label: 'Kategorie',
    component: CalendarStep,
  },
  {
    key: 'services',
    label: 'Leistungen',
    component: ServicesStep,
  },
  {
    key: 'mobility',
    label: 'Mobilität',
    component: MobilityStep,
  },
  {
    key: 'appointment',
    label: 'Termin',
    component: AppointmentStep,
  },
  {
    key: 'personal-data',
    label: 'Meine Daten',
    component: PersonalDetailsStep,
  },
  {
    key: 'confirmation',
    label: 'Bestätigung',
    component: ConfirmationStep,
  },
];

// eslint-disable-next-line consistent-return
function findService(categories, id) {
  // eslint-disable-next-line no-restricted-syntax
  for (const category of categories) {
    // eslint-disable-next-line no-restricted-syntax
    for (const service of category.attributes.workshop_services.data) {
      if (service.id === id) {
        return service;
      }
    }
  }
}

const CREATE_APPOINTMENT = gql`
mutation CreateAppointment($calendar: ID!, $request: CreateAppointmentInput!){
  workshopCreateAppointment(calendar: $calendar, request: $request)
}
`;

const CHECK_SMS_CODE = gql`
mutation CheckSmsCode($calendar: ID!, $code: String!, $appointmentToken: String!){
  workshopCheckSmsCode(calendar: $calendar, code: $code, appointmentToken: $appointmentToken)
}
`;
